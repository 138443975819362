import React, {useEffect} from 'react'
import Term from './Term';

const TermPage = ({setShowBlock, setButtonShow, setShowTerm, setWhiteWrapper}) => {
    useEffect(() => {
        // setShowBlock(false);
        setButtonShow(false)
        // setShowTerm(true);
        setWhiteWrapper(true)
    }, []);
  return (
    <div>
      <Term/>
    </div>
  )
}

export default TermPage