import React, { useState, useEffect,  useRef } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import { TypeAnimation } from 'react-type-animation';
import CustomTitle from '../components/UI/title/CustomTitle';
import { useHistory } from 'react-router-dom';

const Sell = ({setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setButtonTime,  buttonStatus, setButtonStatus,setEventProperties, sessionId, setEventName}) => {
    const history = useHistory();

    useEffect(() => {
        if(setButtonText) {
            setButtonText('Continue')
        }
        if(setButtonShow) {
            setButtonShow(true);
        }
        if(setButtonTime) {
            setButtonTime(150);
        }

        setSelectedOption(null)

        if(buttonStatus == 'Summary') {
            setButtonText('Save');
        }
        if(setEventName) {
            setEventName('web_products_type')
        }
        
    }, []);

    useEffect(() => {
        if(buttonStatus == 'SummaryLoad') {
            setButtonStatus("");
            history.push('/summary');
        }
    }, [buttonStatus]);



    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "Products I buy or make myself",
            img: 'sell1.png',
            text1: "You're in high-performing hands!",
            text2: "We've successfully boosted sales for businesses like yours with creatives and online ads.",
            productType: 'PRODUCT'
        },
        {
            id: 'option2',
            checked: false,
            text: "Digital products",
            img: 'sell2.png',
            text1: "You've made a great choice!",
            text2: "Our tools effectively help digital products increase their sales through creatives and online ads.",
            productType: 'PRODUCT'
        },
        {
            id: 'option3',
            checked: false,
            text: "Dropshipping products",
            img: 'sell3.png',
            text1: "Dropshippers love our creatives!",
            text2: "Many dropshippers have experienced growth using our creatives and online ads.",
            productType: 'PRODUCT'
        },
        {
            id: 'option4',
            checked: false,
            text: "Services",
            img: 'sell4.png',
            text1: "You came to the right place!",
            text2: "Businesses in the service sector see a substantial increase in sales after using our ads.",
            productType: 'SERVICE'
        },
    ];

    useEffect(() => {
        const storedSellValue = localStorage.getItem('sellId');
        if (storedSellValue) {
            setSelectedOption(storedSellValue);
            const selectedOption = initialCheckboxState.find(option => option.id === storedSellValue);
            const selectedOptionText = selectedOption ? selectedOption.text : "";
            if( setEventProperties) {
                setEventProperties({
                    session_id: sessionId,
                    web_entrance: 'signup',
                    web_product_type: selectedOptionText
                })
            }
 
        }
    }, []);

    const [blockHeight, setBlockHeight] = useState(58);
    const blockHeightRef = useRef(null);

    const handleCheckboxChange = (checkbox) => {
        // if (blockHeightRef.current) {
        //     const newHeight = blockHeightRef.current.offsetHeight;
        //     if(blockHeight < newHeight) {
        //         setBlockHeight(newHeight);
        //     }
        // }
        var profilesJSON = localStorage.getItem('business-profiles');
        localStorage.setItem('direction', checkbox.productType);
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function(profile) {
                profile.productsType = checkbox.text;
                profile.direction = checkbox.productType;
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                productsType: checkbox.text,
                direction: checkbox.productType
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
        if(setEventProperties) {
            setEventProperties({
                session_id: sessionId,
                web_entrance: 'signup',
                web_product_type: checkbox.text
            })
        }


        setShowFisrtText(false)
        setShowSecondText(false)
        setSelectedOption(checkbox.id);
        localStorage.setItem('sellId', checkbox.id);
        localStorage.setItem('sell', checkbox.text);

        
        setShowTyping(true);
        setFisrtText(checkbox.text1)
        setSecondText(checkbox.text2)
        setTimeout(() => {
            setShowFisrtText(true)
        }, 100);

        setError(false);
    };

    const [showTyping, setShowTyping] = useState(false);
    const [fisrtText, setFisrtText] = useState('');
    const [secondText, setSecondText] = useState('');
    const [showFisrtText, setShowFisrtText] = useState(false);
    const [showSecondText, setShowSecondText] = useState(false);

    const handleTypingEnd = () => {
        setShowSecondText(true);
    };

    


    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div style={{ paddingBottom: "12rem" }}>
            <CustomTitle style={{ marginBottom: '24rem' }} title="What do you want to sell?"/>
            {initialCheckboxState.map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ marginBottom: '20rem' }}
                >
                    <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} alt="" />
                    {checkbox.text}
                </CustomRadio>
            ))}

            {showTyping && (
                <div className="typing-block" ref={blockHeightRef} style={{ marginBottom: '20rem', marginTop: '24rem', minHeight: typeHeight }}>
                    {showFisrtText && (
                  
                        <TypeAnimation
                        sequence={[secondText]}
                        wrapper="span"
                        speed={80}
                        style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                        repeat={1}
                        cursor={false}
                    />
                    )}
                    {/* {showSecondText && (
                             <TypeAnimation
                             sequence={[
                                 fisrtText,
                                 () => {
                                     handleTypingEnd();
                                 },
                             ]}
                             wrapper="span"
                             speed={80}
                             style={{ fontSize: '12rem', display: 'block', fontWeight: "700" }}
                             repeat={1}
                             cursor={false}
                         />
                    )} */}
                </div>
            )}

            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton onClick={() => {
                        if (areAllCheckboxesUnchecked()) {
                            setError(true);setTimeout(() => {
                            setError(false);
                        }, 1000);
                        } else {
                            setError(false);
                            next();
                        }
                    }} className={areAllCheckboxesUnchecked() ? (error ? 'error' : 'disable') : undefined}>
                        Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </div>
    );
}

export default Sell;
