import React, { useEffect, useState } from 'react';
import CustomTitle from '../components/UI/title/CustomTitle';
import LogoDark from '../components/Logo/LogoDark';
import { WithWizard } from 'react-albus-react18';
import MyButtonReverse from '../components/UI/button/MyButtonReverse';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Term from './Term';
import mixpanel from 'mixpanel-browser';
const Graph = ({ setShowTermBundle, setButtonShow, setWhiteWrapper, setBlockPaddingBottom, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonShow(false);
        setWhiteWrapper(true)
        setBlockPaddingBottom('32rem')
    }, []);

    const [predictStart, setPredictStart] = useState('Dec 23')
    const [predictEnd, setPredictEnd] = useState('Apr 24')
    const [name, setName] = useState("");
    const [orders, setOrders] = useState('');
    const [date, setDate] = useState();
    useEffect(() => {
        const predictValue = localStorage.getItem('predict');
        const today = new Date();

        let daysToAdd;
        switch (predictValue) {
            case 'option1':
                daysToAdd = 75;
                break;
            case 'option2':
                daysToAdd = 120;
                break;
            case 'option3':
                daysToAdd = 210;
                break;
            case 'option4':
                daysToAdd = 300;
                break;
            default:
                daysToAdd = 0;
        }
        const endDate = new Date();
        endDate.setDate(today.getDate() + 60);
        setPredictStart(`${today.toLocaleString('en-US', { month: 'short' })} ${today.getDate()}`);
        setPredictEnd(`${endDate.toLocaleString('en-US', { month: 'short' })} ${endDate.getDate()}`);
        setDate(`${endDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}`);
        setButtonShow(false);
        if (localStorage.getItem('name')) {
            setName(localStorage.getItem('name'))
        }
        if (localStorage.getItem('order2')) {
            const order2 = localStorage.getItem('order2');
            const [minValue, maxValue] = order2.replace('+', '').split(' - ').map(Number);
            const result = ((maxValue - minValue) / 2 + minValue) * 1.55;
            if (result >= 1000) {
                setOrders((Math.round(result) / 1000).toFixed(1).replace('.0', '') + 'k')
            } else {
                setOrders(Math.round(result))
            }
        }
    }, []);
    const history = useHistory();
    const [buttonStatus, setButtonStatus] = useState('');
    const [showCongrats, setShowCongrats] = useState(false);

    const [showErrors, setShowErrors] = useState(false);

    const handleClick = (next) => {
        setButtonStatus('load')
        mixpanel.track('web_booster_1', {
            session_id: sessionId,
            web_level: 'TRIAL',
            web_plan__name: localStorage.getItem('plan'),
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            user_id: localStorage.getItem('userId'),
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
        oneClick()
    }



    const oneClick = () => {
        const fullJSON = localStorage.getItem('business-profiles');
        const profile = JSON.parse(fullJSON)[0];

        const data = JSON.stringify({
            "currency": "USD",
            "successUrl": "https://app.zee.sale/brandname",
            "failUrl": "https://app.zee.sale/brandname",
            "amount": 1,
            "customerEmail": profile.email,
            "productId": "f589e426-8687-41c6-8378-1d139de06f0b",
            "paymentType": "1-click"
        })

        axios.post(`https://staging.zeely.link/payments/solidgate/one-click`, data, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                if (response.data.success == true) {
                    setTimeout(() => {
                        setShowCongrats(true)
                        mixpanel.track('web_click_booster_1', {
                            session_id: sessionId,
                            web_level: 'GROWTH',
                            web_plan__name: localStorage.getItem('plan'),
                            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                            user_id: localStorage.getItem('userId'),
                            web_booster_pay: 'SUCCESS',
                            web_utm_campaign: localStorage.getItem("utmCampaign"),
                            web_utm_source: localStorage.getItem("utmSource"),
                            web_utm_medium: localStorage.getItem("utmMedium"),
                            web_utm_content: localStorage.getItem("utmContent"),
                            web_utm_term: localStorage.getItem("utmTerm"),
                            isDebug: false,
funnel: 'MAIN_v1',
                        });
                        localStorage.setItem('web_booster_pay', true)
                        setTimeout(() => {
                            history.push('/brandname');
                        }, 2000);
                    }, 1000);
                } else if (response.data.verifyUrl) {
                    window.location = response.verifyUrl;
                } else if (response.data.statusCode == 500) {
                    setTimeout(() => {
                        mixpanel.track('web_click_booster_1', {
                            session_id: sessionId,
                            web_level: 'TRIAL',
                            web_plan__name: localStorage.getItem('plan'),
                            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                            user_id: localStorage.getItem('userId'),
                            web_booster_pay: 'NO_SUCCESS',
                            web_utm_campaign: localStorage.getItem("utmCampaign"),
                            web_utm_source: localStorage.getItem("utmSource"),
                            web_utm_medium: localStorage.getItem("utmMedium"),
                            web_utm_content: localStorage.getItem("utmContent"),
                            web_utm_term: localStorage.getItem("utmTerm"),
                            isDebug: false,
funnel: 'MAIN_v1',
                        });
                        setShowErrors(true)
                        setTimeout(() => {
                            history.push('/brandname');
                        }, 2000);
                    }, 1000);
                }
            })
            .catch(error => {
                sendAlert("https://staging.zeely.link/payments/solidgate/one-click", data, error.response.data.message)
                setTimeout(() => {
                    setShowErrors(true)
                    mixpanel.track('web_click_booster_1', {
                        session_id: sessionId,
                        web_level: 'TRIAL',
                        web_plan__name: localStorage.getItem('plan'),
                        web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                        user_id: localStorage.getItem('userId'),
                        web_booster_pay: 'NO_SUCCESS',
                        web_utm_campaign: localStorage.getItem("utmCampaign"),
                        web_utm_source: localStorage.getItem("utmSource"),
                        web_utm_medium: localStorage.getItem("utmMedium"),
                        web_utm_content: localStorage.getItem("utmContent"),
                        web_utm_term: localStorage.getItem("utmTerm"),
                        isDebug: false,
funnel: 'MAIN_v1',
                    });
                    setTimeout(() => {
                        history.push('/brandname');
                    }, 2000);
                }, 1000);
            });
    };

    const sendAlert = async ( path, data, errorLog)=> {
        const now = new Date();
        const utcString = now.toISOString();

        const formdata = new FormData();
        formdata.append("domain", window.location.hostname);
        formdata.append("time", utcString);
        formdata.append("email", localStorage.getItem('email'));
        formdata.append("path", path);
        formdata.append("data", data);
        formdata.append("error", errorLog);
        console.log(formdata)
        try {
            const response = await axios.post("https://zee.sale/send-alert.php", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [showBlock, setShowBlock] = useState(true);
    const [showTerm, setShowTerm] = useState(false);

    const termChange = () => {
        setShowTermBundle(true)
    };
    const termClose = () => {
        setShowBlock(true);
        setShowTerm(false);
        setBlockPaddingBottom('32rem')
    }

    return (
        <>
            {showBlock && (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                    <Helmet>
                        <meta name="theme-color" content="#fff"></meta>
                    </Helmet>
                    {/* <LogoDark style={{ marginBottom: '32rem', }} /> */}
                    <CustomTitle style={{ marginBottom: "12rem" }} title="Did you know?" />
                    <p style={{ marginBottom: "24rem" }} className="text">Combining sales strategy with additional ad opportunities can result in up to a 55% increase!</p>
                    <div style={{ aspectRatio: "89/75", margin: '0 auto', width: '282rem', position: 'relative' }} >
                        <span style={{
                            position: "absolute",
                            width: '88rem',
                            height: '50rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12rem',
                            textAlign: 'center',
                            fontWeight: '500',
                            lineHeight: '1.2',
                            top: 0,
                            right: 0,
                        }}>You with<br />Booster:<br />
                            {orders} orders</span>
                        <img src={process.env.PUBLIC_URL + '/img/graph.svg'} style={{ width: '100%' }} alt="" />
                    </div>
                    <div className="predict-wrapper" style={{ margin: '-14rem auto 0', width: '282rem' }}>
                        <div className="predict-item">
                            {predictStart}
                        </div>
                        <div className="predict-item">
                            {predictEnd}
                        </div>
                    </div>
                    {/* <div style={{ aspectRatio: "101/85", margin: "0 auto 35rem", width: '282rem' }}>
                 <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/img/graph.svg'} alt="" />
             </div> */}
                    {/* <p className="text" style={{ marginBottom: '32rem', }}>
             We want you to find success, so we’re offering <strong>78% OFF</strong> Premium bundle
             </p> */}
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: '1' }}>
                        <WithWizard
                            render={({ next, previous, step, steps }) => (
                                <>
                                    <MyButtonReverse onClick={() => handleClick(next)} buttonStatus={buttonStatus} style={{ marginBottom: '0rem' }}>

                                        <svg style={{ marginRight: '8rem', marginLeft: '0rem' }} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5003 1.42969C12.8672 1.42969 14.786 3.34847 14.786 5.7154L14.7875 7.16021C15.3895 7.19752 15.7735 7.30286 16.1695 7.51461C16.6432 7.76793 17.0192 8.14395 17.2725 8.61764C17.5477 9.13213 17.6431 9.62657 17.6431 10.6053V15.1112C17.6431 16.0899 17.5477 16.5844 17.2725 17.0989C17.0192 17.5726 16.6432 17.9486 16.1695 18.2019C15.655 18.4771 15.1605 18.5725 14.1818 18.5725H6.81873C5.84002 18.5725 5.34558 18.4771 4.83109 18.2019C4.3574 17.9486 3.98138 17.5726 3.72805 17.0989C3.4529 16.5844 3.35742 16.0899 3.35742 15.1112V10.6053C3.35742 9.62657 3.4529 9.13213 3.72805 8.61764C3.98138 8.14395 4.3574 7.76793 4.83109 7.51461C5.22717 7.30278 5.61138 7.19744 6.21381 7.16017L6.21456 5.7154C6.21456 3.34847 8.13334 1.42969 10.5003 1.42969ZM14.1818 8.57254H6.81873C6.05568 8.57254 5.78404 8.625 5.5048 8.77434C5.28007 8.89453 5.10797 9.06662 4.98779 9.29135C4.83845 9.57059 4.78599 9.84224 4.78599 10.6053V15.1112C4.78599 15.8743 4.83845 16.1459 4.98779 16.4252C5.10797 16.6499 5.28007 16.822 5.5048 16.9422C5.78404 17.0915 6.05568 17.144 6.81873 17.144H14.1818C14.9449 17.144 15.2165 17.0915 15.4958 16.9422C15.7205 16.822 15.8926 16.6499 16.0128 16.4252C16.1621 16.1459 16.2146 15.8743 16.2146 15.1112V10.6053C16.2146 9.84224 16.1621 9.57059 16.0128 9.29135C15.8926 9.06662 15.7205 8.89453 15.4958 8.77434C15.2165 8.625 14.9449 8.57254 14.1818 8.57254ZM10.5003 10.7154C10.8948 10.7154 11.2146 11.0352 11.2146 11.4297V13.5725C11.2146 13.967 10.8948 14.2868 10.5003 14.2868C10.1058 14.2868 9.78599 13.967 9.78599 13.5725V11.4297C9.78599 11.0352 10.1058 10.7154 10.5003 10.7154ZM10.5003 2.85826C8.92232 2.85826 7.64314 4.13745 7.64314 5.7154V7.14397H13.3574V5.7154C13.3574 4.13745 12.0782 2.85826 10.5003 2.85826Z" fill="#5BF0A5" />
                                        </svg>
                                        Continue with Booster
                                    </MyButtonReverse >
                                </>
                            )}
                        />
                        <div className="block-bottom" style={{ marginTop: '12rem', color: '#6D787E' }}> *Combining AI tools with the Premium Booster can make it 5 times easier to boost your sales. By continuing, $39.95 will be charged to your payment method per our <span onClick={termChange}>Subscription Terms</span>.</div>
                    </div>
                    {showCongrats && (
                        <div className="congrats">
                            <div className="congrats-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#F2F4F6" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9996 13.4521C21.8345 13.3179 21.6276 13.1577 21.386 12.9866C20.7404 12.5295 19.823 11.9782 18.7727 11.6465C17.7202 11.3141 16.4927 11.1901 15.271 11.6511C14.0415 12.1149 12.9162 13.1339 12.0044 14.897C11.1589 16.5318 11.2056 18.3232 11.728 20.0602C12.2487 21.7912 13.2546 23.5179 14.4079 25.0935C16.7157 28.2464 19.7094 30.9222 20.9739 31.9947C21.5702 32.5005 22.4289 32.5005 23.0252 31.9947C24.2897 30.9222 27.2835 28.2464 29.5912 25.0935C30.7445 23.5179 31.7505 21.7912 32.2711 20.0602C32.7935 18.3232 32.8402 16.5318 31.9947 14.897C31.083 13.1339 29.9576 12.1149 28.7282 11.6511C27.5064 11.1901 26.2789 11.3141 25.2264 11.6465C24.1761 11.9782 23.2587 12.5295 22.6131 12.9866C22.3715 13.1577 22.1646 13.3179 21.9996 13.4521ZM22.4497 14.7806L22.4509 14.7795L22.4604 14.7706C22.4696 14.762 22.4842 14.7485 22.504 14.7305C22.5438 14.6945 22.6042 14.6409 22.6835 14.5738C22.8421 14.4395 23.0746 14.2527 23.3643 14.0476C23.9485 13.634 24.7425 13.1626 25.6179 12.8861C26.491 12.6104 27.4036 12.5408 28.2692 12.8674C29.1272 13.1911 30.0367 13.9408 30.84 15.4942C31.4832 16.7379 31.4856 18.1582 31.0261 19.6858C30.5648 21.2196 29.6511 22.8106 28.5422 24.3257C26.3255 27.3542 23.4242 29.9516 22.1843 31.0033C22.0731 31.0976 21.926 31.0976 21.8148 31.0033C20.5749 29.9516 17.6737 27.3542 15.4569 24.3257C14.348 22.8106 13.4343 21.2196 12.973 19.6858C12.5135 18.1582 12.5159 16.7379 13.1591 15.4942C13.9624 13.9408 14.8719 13.1911 15.7299 12.8674C16.5956 12.5408 17.5081 12.6104 18.3812 12.8861C19.2566 13.1626 20.0506 13.634 20.6348 14.0476C20.9245 14.2527 21.157 14.4395 21.3156 14.5738C21.3949 14.6409 21.4554 14.6945 21.4951 14.7305C21.5149 14.7485 21.5295 14.762 21.5387 14.7706L21.5482 14.7795L21.5494 14.7806L21.9996 15.2129L22.4497 14.7806Z" fill="black" />
                                    <path d="M26.3394 15.8477C26.3664 15.7128 26.5594 15.7128 26.5863 15.8477C26.8144 16.9878 27.7055 17.879 28.8456 18.107C28.9806 18.134 28.9806 18.3269 28.8456 18.3539C27.7055 18.5819 26.8144 19.4731 26.5863 20.6132C26.5594 20.7481 26.3664 20.7481 26.3394 20.6132C26.1114 19.4731 25.2202 18.5819 24.0802 18.3539C23.9452 18.3269 23.9452 18.134 24.0802 18.107C25.2202 17.879 26.1114 16.9878 26.3394 15.8477Z" fill="black" />
                                </svg>

                            </div>
                            <div className="congrats-info">
                                <strong>Congrats on the upgrade!</strong>
                                <span>We've added premium tools to your account</span>
                            </div>
                        </div>
                    )}


                    {showErrors && (
                        <div className="congrats">
                            <div className="congrats-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#EF2C4F" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4271 12C25.5189 11.999 26.5664 12.4318 27.3392 13.2032L30.7873 16.6451C31.56 17.4165 31.9947 18.4632 31.9956 19.5551L32 24.4271C32.001 25.5189 31.5682 26.5664 30.7968 27.3392L27.3549 30.7873C26.5835 31.56 25.5368 31.9947 24.4449 31.9956L19.5729 32C18.4811 32.001 17.4336 31.5682 16.6608 30.7968L13.2127 27.3549C12.44 26.5835 12.0053 25.5368 12.0044 24.4449L12 19.5729C11.999 18.4811 12.4318 17.4336 13.2032 16.6608L16.6451 13.2127C17.4165 12.44 18.4632 12.0053 19.5551 12.0044L24.4271 12ZM20.5881 17.2474C20.5403 16.435 21.1862 15.75 22 15.75C22.8138 15.75 23.4597 16.435 23.4119 17.2474L23.0587 23.2517C23.0257 23.8123 22.5615 24.25 22 24.25C21.4385 24.25 20.9743 23.8123 20.9413 23.2517L20.5881 17.2474ZM22 25.25C21.1716 25.25 20.5 25.9216 20.5 26.75C20.5 27.5784 21.1716 28.25 22 28.25C22.8284 28.25 23.5 27.5784 23.5 26.75C23.5 25.9216 22.8284 25.25 22 25.25Z" fill="white" />
                                </svg>


                            </div>
                            <div className="congrats-info">
                                <strong>Oops, something went wrong</strong>
                                <span>We were unable to make the payment </span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {showTerm && (<>
                <div className="term-back" onClick={termClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                    </svg>
                </div>
                <Term></Term></>
            )}



        </>

    );
}

export default Graph;
