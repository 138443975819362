import React, { useEffect, useState } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './home1.scss'
import './home1-media.scss'
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination } from 'swiper/modules';
import MyButton from '../../components/UI/button/MyButton';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
const Page7 = ({ sethomeShow, setButtonStatus, setBlockPaddingBottom, setHandShow, setButtonShow,  sessionId }) => {
    const history = useHistory();
    function documentFontSize() {
        if (window.innerWidth > 991 && window.innerWidth < 1800) {
            document.documentElement.style.fontSize = 'calc(100vw / 1440)';
            console.log(window.innerWidth)
        } else if (window.innerWidth > 1799) {
            document.documentElement.style.fontSize = '1.2px';
        } else {
            document.documentElement.style.fontSize = '';
        }
    }

    useEffect(() => {
        sethomeShow(true)
        setButtonStatus('')
        setHandShow(false)
        setBlockPaddingBottom('8rem')
        setButtonShow(false)
        setButtonShow(false)
        documentFontSize()
    }, []);
    const [isVisible, setIsVisible] = useState(window.innerWidth > 991);

    useEffect(() => {
        const handleResize = () => {
            setIsVisible(window.innerWidth > 991);
            documentFontSize()
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'form_start'
        });
        mixpanel.track('web_start', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });
        const data = {
            "pageId": localStorage.getItem('HomeId')
        };
        setTimeout(() => {
            history.push('/stage');
        }, 150);
        axios.post("https://staging.zeely.link/pages/landings", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {

            })
            .catch(error => {

            });
    }
    return (
        <div>
            {isVisible ? (
                <div style={{ paddingBottom: "32rem" }} className='page7'>

                    <div className="page7__logo">
                        <svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5105_63146)">
                                <path d="M45.8492 11.0493C45.8245 9.19955 45.2074 7.6704 43.973 6.46188C42.7387 5.27803 41.1835 4.6861 39.2826 4.6861C37.3818 4.6861 35.8019 5.27803 34.5676 6.46188C33.3086 7.6704 32.6914 9.17489 32.6914 11.0247C32.6914 12.8744 33.3086 14.3789 34.5676 15.5628C35.8019 16.7466 37.3818 17.3386 39.2826 17.3386C40.7885 17.3386 42.1463 16.9193 43.3312 16.0561C44.5161 15.1928 45.2814 14.1076 45.627 12.7758H42.3931C41.9241 13.8856 40.7145 14.7242 39.3073 14.7242C37.6287 14.7242 36.2956 13.6144 35.9006 12.0112H45.7505C45.8245 11.7399 45.8492 11.4193 45.8492 11.0493ZM39.3073 7.30045C40.8626 7.30045 42.2203 8.21301 42.714 9.56951H36.0241C36.5425 8.18834 37.8015 7.30045 39.3073 7.30045Z" fill="black" />
                                <path d="M59.8384 11.0493C59.8137 9.19955 59.1966 7.6704 57.9623 6.46188C56.728 5.27803 55.1727 4.6861 53.2719 4.6861C51.371 4.6861 49.7911 5.27803 48.5568 6.46188C47.2978 7.6704 46.6806 9.17489 46.6806 11.0247C46.6806 12.8744 47.2978 14.3789 48.5568 15.5628C49.7911 16.7466 51.371 17.3386 53.2719 17.3386C54.7777 17.3386 56.1355 16.9193 57.3204 16.0561C58.5054 15.1928 59.2706 14.1076 59.6163 12.7758H56.3823C55.9133 13.8856 54.7037 14.7242 53.2966 14.7242C51.6179 14.7242 50.2848 13.6144 49.8898 12.0112H59.7397C59.8137 11.7399 59.8384 11.4193 59.8384 11.0493ZM53.2966 7.30045C54.8518 7.30045 56.2095 8.21301 56.7033 9.56951H50.0133C50.5317 8.18834 51.7907 7.30045 53.2966 7.30045Z" fill="black" />
                                <path d="M61.3364 17.1166H64.5456V0H61.3364V17.1166Z" fill="black" />
                                <path d="M72.2983 12.6031L68.8915 4.93274H65.4602L70.7924 15.8094L67.6572 22H71.0146L78.9882 4.93274H75.6309L72.2983 12.6031Z" fill="black" />
                                <path d="M28.8741 4.92969H32.542L24.1367 17.1139H20.4688L28.8741 4.92969Z" fill="black" />
                                <path d="M20.8008 4.92969H29.8035V7.67711H20.8008V4.92969Z" fill="black" />
                                <path d="M23.2812 14.3672H31.9378V17.1146H23.2812V14.3672Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.611262 14.0801L0.610657 14.0786H15.4581L15.4575 14.0801H0.611262ZM15.8484 12.8794C15.7998 13.0822 15.7434 13.282 15.6797 13.4785H0.389061C0.325351 13.282 0.269022 13.0822 0.220399 12.8794H15.8484ZM3.80796 17.8306C3.80874 17.8311 3.80953 17.8315 3.81031 17.832H12.2585C12.2593 17.8315 12.2601 17.8311 12.2608 17.8306H3.80796ZM2.96491 17.2305C2.73435 17.0429 2.5144 16.8429 2.30611 16.6314H13.7627C13.5544 16.8429 13.3344 17.0429 13.1039 17.2305H2.96491ZM0.890553 14.6802C1.02443 14.9392 1.172 15.19 1.33232 15.4316H14.7365C14.8968 15.19 15.0444 14.9392 15.1782 14.6802H0.890553ZM8.0344 19.0295C6.95586 19.0295 5.92696 18.8172 4.98728 18.4321H11.0815C10.1418 18.8172 9.11293 19.0295 8.0344 19.0295ZM0.0282928 11.6855C0.011408 11.4852 0.00188665 11.2828 0 11.0786H16.0688C16.0669 11.2828 16.0574 11.4852 16.0405 11.6855H0.0282928ZM0.077551 9.88086C0.113286 9.62568 0.161031 9.37435 0.220197 9.12745H15.8486C15.9078 9.37435 15.9555 9.62568 15.9912 9.88086H0.077551ZM0.389583 8.52589L0.389111 8.52734H15.6797L15.6792 8.52589H0.389583ZM0.611327 7.92578C0.696205 7.72169 0.789272 7.52185 0.890118 7.32667H15.1787C15.2795 7.52185 15.3726 7.72169 15.4575 7.92578H0.611327ZM1.65171 6.12695C1.81224 5.91758 1.98285 5.71633 2.16283 5.52394H13.906C14.0859 5.71633 14.2566 5.91758 14.4171 6.12695H1.65171ZM8.0344 2.97656C9.68483 2.97656 11.219 3.47367 12.4952 4.32617H3.57364C4.84977 3.47367 6.38397 2.97656 8.0344 2.97656Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90363 6.125C7.23907 5.68775 7.61854 5.28597 8.03541 4.92628H2.78534C2.36847 5.28597 1.98901 5.68775 1.65356 6.125H6.90363ZM6.48513 6.72511H1.23507C0.994319 7.10618 0.784785 7.5089 0.610052 7.92969H5.86012C6.03485 7.5089 6.24439 7.10618 6.48513 6.72511ZM0.0781739 9.87891C0.143209 9.41533 0.247884 8.96445 0.388666 8.5298H5.63873C5.49795 8.96445 5.39327 9.41533 5.32824 9.87891H0.0781739ZM0.0168479 10.479C0.00567352 10.6522 0 10.827 0 11.003C0 11.2309 0.00950828 11.4566 0.0281502 11.6797H5.27822C5.25957 11.4566 5.25007 11.2309 5.25007 11.003C5.25007 10.827 5.25574 10.6522 5.26691 10.479H0.0168479ZM0.38941 13.4785C0.26377 13.091 0.166831 12.6906 0.101097 12.2798H5.35116C5.4169 12.6906 5.51384 13.091 5.63948 13.4785H0.38941ZM0.611006 14.0786C0.808476 14.5537 1.05032 15.0058 1.33137 15.4297H6.58144C6.30039 15.0058 6.05854 14.5537 5.86107 14.0786H0.611006ZM2.96286 17.2285C2.52465 16.8718 2.12482 16.4699 1.7705 16.0298H7.02056C7.37488 16.4699 7.77472 16.8718 8.21293 17.2285H2.96286ZM3.80515 17.8286C5.03362 18.5899 6.4828 19.0295 8.03475 19.0295C8.95398 19.0295 9.83716 18.8752 10.6598 18.5913C10.0936 18.3959 9.55606 18.139 9.05522 17.8286H3.80515ZM10.6598 3.4147C10.0054 3.64055 9.38942 3.94848 8.82406 4.32617H3.57399C4.85012 3.47367 6.38432 2.97656 8.03475 2.97656C8.95398 2.97656 9.83716 3.13077 10.6598 3.4147Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5105_63146">
                                    <rect width="79" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="page7-content">
                        <div className="page7-left">
                            <CustomTitle style={{ marginBottom: "24rem" }} title={localStorage.getItem('HomeTitle') === 'null' || localStorage.getItem('HomeTitle') === null ? "Personalized increase business sales plan" : localStorage.getItem('HomeTitle')}  />
                            <p dangerouslySetInnerHTML={{ __html: (localStorage.getItem('HomeText') === 'null' || localStorage.getItem('HomeText') === null ? 'Take our <span>3-minute quiz</span> quiz and<br> get a smart AI-powered promotion plan for your brand' : localStorage.getItem('HomeText')) }} />
                        </div>
                        <div className="page7-right">

                            <div className="page7-wrapper">
                                <div className="page7-item" onClick={() => handleClick()}>
                                    <img src={process.env.PUBLIC_URL + '/img/page7-1.png'} alt="" />
                                    <div className="page7-item-bottom">
                                        <span>Age: 18-24</span>
                                        <div className="arrow" >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                                <div className="page7-item" onClick={() => handleClick()}>
                                    <img src={process.env.PUBLIC_URL + '/img/page7-2.png'} alt="" />
                                    <div className="page7-item-bottom">
                                        <span>Age: 25-34</span>
                                        <div className="arrow" >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                                <div className="page7-item" onClick={() => handleClick()}>
                                    <img src={process.env.PUBLIC_URL + '/img/page7-3.png'} alt="" />
                                    <div className="page7-item-bottom">
                                        <span>Age: 35-44</span>
                                        <div className="arrow" >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                                <div className="page7-item" onClick={() => handleClick()}>
                                    <img src={process.env.PUBLIC_URL + '/img/page7-4.png'} alt="" />
                                    <div className="page7-item-bottom">
                                        <span>Age: 45+</span>
                                        <div className="arrow" >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div  className='page7'>
                    <Helmet>

                        <meta name="theme-color" content="#fff"></meta>
                    </Helmet>
                    <div className="page7__logo">
                        <svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5105_63146)">
                                <path d="M45.8492 11.0493C45.8245 9.19955 45.2074 7.6704 43.973 6.46188C42.7387 5.27803 41.1835 4.6861 39.2826 4.6861C37.3818 4.6861 35.8019 5.27803 34.5676 6.46188C33.3086 7.6704 32.6914 9.17489 32.6914 11.0247C32.6914 12.8744 33.3086 14.3789 34.5676 15.5628C35.8019 16.7466 37.3818 17.3386 39.2826 17.3386C40.7885 17.3386 42.1463 16.9193 43.3312 16.0561C44.5161 15.1928 45.2814 14.1076 45.627 12.7758H42.3931C41.9241 13.8856 40.7145 14.7242 39.3073 14.7242C37.6287 14.7242 36.2956 13.6144 35.9006 12.0112H45.7505C45.8245 11.7399 45.8492 11.4193 45.8492 11.0493ZM39.3073 7.30045C40.8626 7.30045 42.2203 8.21301 42.714 9.56951H36.0241C36.5425 8.18834 37.8015 7.30045 39.3073 7.30045Z" fill="black" />
                                <path d="M59.8384 11.0493C59.8137 9.19955 59.1966 7.6704 57.9623 6.46188C56.728 5.27803 55.1727 4.6861 53.2719 4.6861C51.371 4.6861 49.7911 5.27803 48.5568 6.46188C47.2978 7.6704 46.6806 9.17489 46.6806 11.0247C46.6806 12.8744 47.2978 14.3789 48.5568 15.5628C49.7911 16.7466 51.371 17.3386 53.2719 17.3386C54.7777 17.3386 56.1355 16.9193 57.3204 16.0561C58.5054 15.1928 59.2706 14.1076 59.6163 12.7758H56.3823C55.9133 13.8856 54.7037 14.7242 53.2966 14.7242C51.6179 14.7242 50.2848 13.6144 49.8898 12.0112H59.7397C59.8137 11.7399 59.8384 11.4193 59.8384 11.0493ZM53.2966 7.30045C54.8518 7.30045 56.2095 8.21301 56.7033 9.56951H50.0133C50.5317 8.18834 51.7907 7.30045 53.2966 7.30045Z" fill="black" />
                                <path d="M61.3364 17.1166H64.5456V0H61.3364V17.1166Z" fill="black" />
                                <path d="M72.2983 12.6031L68.8915 4.93274H65.4602L70.7924 15.8094L67.6572 22H71.0146L78.9882 4.93274H75.6309L72.2983 12.6031Z" fill="black" />
                                <path d="M28.8741 4.92969H32.542L24.1367 17.1139H20.4688L28.8741 4.92969Z" fill="black" />
                                <path d="M20.8008 4.92969H29.8035V7.67711H20.8008V4.92969Z" fill="black" />
                                <path d="M23.2812 14.3672H31.9378V17.1146H23.2812V14.3672Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.611262 14.0801L0.610657 14.0786H15.4581L15.4575 14.0801H0.611262ZM15.8484 12.8794C15.7998 13.0822 15.7434 13.282 15.6797 13.4785H0.389061C0.325351 13.282 0.269022 13.0822 0.220399 12.8794H15.8484ZM3.80796 17.8306C3.80874 17.8311 3.80953 17.8315 3.81031 17.832H12.2585C12.2593 17.8315 12.2601 17.8311 12.2608 17.8306H3.80796ZM2.96491 17.2305C2.73435 17.0429 2.5144 16.8429 2.30611 16.6314H13.7627C13.5544 16.8429 13.3344 17.0429 13.1039 17.2305H2.96491ZM0.890553 14.6802C1.02443 14.9392 1.172 15.19 1.33232 15.4316H14.7365C14.8968 15.19 15.0444 14.9392 15.1782 14.6802H0.890553ZM8.0344 19.0295C6.95586 19.0295 5.92696 18.8172 4.98728 18.4321H11.0815C10.1418 18.8172 9.11293 19.0295 8.0344 19.0295ZM0.0282928 11.6855C0.011408 11.4852 0.00188665 11.2828 0 11.0786H16.0688C16.0669 11.2828 16.0574 11.4852 16.0405 11.6855H0.0282928ZM0.077551 9.88086C0.113286 9.62568 0.161031 9.37435 0.220197 9.12745H15.8486C15.9078 9.37435 15.9555 9.62568 15.9912 9.88086H0.077551ZM0.389583 8.52589L0.389111 8.52734H15.6797L15.6792 8.52589H0.389583ZM0.611327 7.92578C0.696205 7.72169 0.789272 7.52185 0.890118 7.32667H15.1787C15.2795 7.52185 15.3726 7.72169 15.4575 7.92578H0.611327ZM1.65171 6.12695C1.81224 5.91758 1.98285 5.71633 2.16283 5.52394H13.906C14.0859 5.71633 14.2566 5.91758 14.4171 6.12695H1.65171ZM8.0344 2.97656C9.68483 2.97656 11.219 3.47367 12.4952 4.32617H3.57364C4.84977 3.47367 6.38397 2.97656 8.0344 2.97656Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90363 6.125C7.23907 5.68775 7.61854 5.28597 8.03541 4.92628H2.78534C2.36847 5.28597 1.98901 5.68775 1.65356 6.125H6.90363ZM6.48513 6.72511H1.23507C0.994319 7.10618 0.784785 7.5089 0.610052 7.92969H5.86012C6.03485 7.5089 6.24439 7.10618 6.48513 6.72511ZM0.0781739 9.87891C0.143209 9.41533 0.247884 8.96445 0.388666 8.5298H5.63873C5.49795 8.96445 5.39327 9.41533 5.32824 9.87891H0.0781739ZM0.0168479 10.479C0.00567352 10.6522 0 10.827 0 11.003C0 11.2309 0.00950828 11.4566 0.0281502 11.6797H5.27822C5.25957 11.4566 5.25007 11.2309 5.25007 11.003C5.25007 10.827 5.25574 10.6522 5.26691 10.479H0.0168479ZM0.38941 13.4785C0.26377 13.091 0.166831 12.6906 0.101097 12.2798H5.35116C5.4169 12.6906 5.51384 13.091 5.63948 13.4785H0.38941ZM0.611006 14.0786C0.808476 14.5537 1.05032 15.0058 1.33137 15.4297H6.58144C6.30039 15.0058 6.05854 14.5537 5.86107 14.0786H0.611006ZM2.96286 17.2285C2.52465 16.8718 2.12482 16.4699 1.7705 16.0298H7.02056C7.37488 16.4699 7.77472 16.8718 8.21293 17.2285H2.96286ZM3.80515 17.8286C5.03362 18.5899 6.4828 19.0295 8.03475 19.0295C8.95398 19.0295 9.83716 18.8752 10.6598 18.5913C10.0936 18.3959 9.55606 18.139 9.05522 17.8286H3.80515ZM10.6598 3.4147C10.0054 3.64055 9.38942 3.94848 8.82406 4.32617H3.57399C4.85012 3.47367 6.38432 2.97656 8.03475 2.97656C8.95398 2.97656 9.83716 3.13077 10.6598 3.4147Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5105_63146">
                                    <rect width="79" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="page7-content">

                        <CustomTitle style={{ marginBottom: "12rem" }} title={localStorage.getItem('HomeTitle') === 'null' || localStorage.getItem('HomeTitle') === null ? "Personalized increase business sales plan" : localStorage.getItem('HomeTitle')}  />

                        <p dangerouslySetInnerHTML={{ __html: (localStorage.getItem('HomeText') === 'null' || localStorage.getItem('HomeText') === null ? 'Take our <span>3-minute quiz</span> quiz and get a smart <br>AI-powered promotion plan for your brand' : localStorage.getItem('HomeText')) }} />
                        <div className="page7-wrapper">
                            <div className="page7-item" onClick={() => handleClick()}>
                                <img src={process.env.PUBLIC_URL + '/img/page7-1.png'} alt="" />
                                <div className="page7-item-bottom">
                                    <span>Age: 18-24</span>
                                    <div className="arrow" >
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="page7-item" onClick={() => handleClick()}>
                                <img src={process.env.PUBLIC_URL + '/img/page7-2.png'} alt="" />
                                <div className="page7-item-bottom">
                                    <span>Age: 25-34</span>
                                    <div className="arrow">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                            <div className="page7-item" onClick={() => handleClick()}>
                                <img src={process.env.PUBLIC_URL + '/img/page7-3.png'} alt="" />
                                <div className="page7-item-bottom">
                                    <span>Age: 35-44</span>
                                    <div className="arrow">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                            <div className="page7-item" onClick={() => handleClick()}>
                                <img src={process.env.PUBLIC_URL + '/img/page7-4.png'} alt="" />
                                <div className="page7-item-bottom">
                                    <span>Age: 45+</span>
                                    <div className="arrow" >
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.31209 0.839913C6.09578 1.05317 6.07549 1.39016 6.25281 1.6266L6.30607 1.68842L9.96667 5.40051L0.6 5.40051C0.268629 5.40051 0 5.66914 0 6.00051C0 6.30639 0.228891 6.55881 0.524737 6.59583L0.6 6.60051H9.96667L6.30607 10.3126C6.09281 10.5289 6.0773 10.8661 6.25796 11.1L6.31209 11.1611C6.52839 11.3744 6.86563 11.3899 7.09953 11.2092L7.1606 11.1551L11.8273 6.42175C12.0384 6.20761 12.056 5.87443 11.88 5.64045L11.8273 5.57926L7.1606 0.845931C6.92795 0.60996 6.54806 0.607266 6.31209 0.839913Z" fill="black" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>

    );
}

export default Page7;
