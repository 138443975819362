import React, {useEffect} from 'react'
import Privacy from './Privacy';

const PrivacyPage = ({setShowBlock, setButtonShow, setShowPrivacy, setWhiteWrapper}) => {
    useEffect(() => {
        // setShowBlock(false);
        setButtonShow(false)
        // setShowPrivacy(true);
        setWhiteWrapper(true)
    }, []);
  return (
    <div>
      <Privacy/>
    </div>
  )
}

export default PrivacyPage