import React from 'react';
import './review.scss';

const Review = ({style, img, name, date, text}) => {
    return (
        <div className="review" style={style}>
            <div className="review-top">
                <div className="review__ava">
                    <img src={process.env.PUBLIC_URL + `/img/${img}`} alt="" />
                </div>
                <div className="review-info">
                    <div className="review__name">{name}</div>
                    <div className="review-info-bottom">
                        <svg width="51" height="10" viewBox="0 0 51 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.31559 0.962115C4.4495 0.709982 4.81078 0.709982 4.94469 0.962116L5.9692 2.89108C6.02068 2.988 6.11397 3.05578 6.22206 3.07479L8.3732 3.45308C8.65438 3.50252 8.76602 3.84612 8.56761 4.05139L7.04965 5.62183C6.97337 5.70075 6.93774 5.81042 6.95306 5.9191L7.25803 8.08185C7.29789 8.36454 7.00561 8.57689 6.74907 8.45162L4.78642 7.49325C4.68779 7.4451 4.57248 7.4451 4.47386 7.49325L2.5112 8.45162C2.25466 8.57689 1.96238 8.36454 2.00224 8.08185L2.30721 5.9191C2.32254 5.81042 2.2869 5.70075 2.21063 5.62183L0.692669 4.05139C0.494257 3.84612 0.605898 3.50252 0.887073 3.45308L3.03821 3.07479C3.14631 3.05578 3.2396 2.988 3.29108 2.89108L4.31559 0.962115Z" fill="#1967FF" />
                            <path d="M14.5763 0.962115C14.7102 0.709982 15.0715 0.709982 15.2054 0.962116L16.2299 2.89108C16.2814 2.988 16.3747 3.05578 16.4828 3.07479L18.6339 3.45308C18.9151 3.50252 19.0268 3.84612 18.8283 4.05139L17.3104 5.62183C17.2341 5.70075 17.1985 5.81042 17.2138 5.9191L17.5188 8.08185C17.5586 8.36454 17.2664 8.57689 17.0098 8.45162L15.0472 7.49325C14.9485 7.4451 14.8332 7.4451 14.7346 7.49325L12.7719 8.45162C12.5154 8.57689 12.2231 8.36454 12.263 8.08185L12.568 5.9191C12.5833 5.81042 12.5476 5.70075 12.4714 5.62183L10.9534 4.05139C10.755 3.84612 10.8666 3.50252 11.1478 3.45308L13.299 3.07479C13.407 3.05578 13.5003 2.988 13.5518 2.89108L14.5763 0.962115Z" fill="#1967FF" />
                            <path d="M24.8361 0.962115C24.97 0.709982 25.3313 0.709982 25.4652 0.962116L26.4897 2.89108C26.5412 2.988 26.6345 3.05578 26.7426 3.07479L28.8937 3.45308C29.1749 3.50252 29.2865 3.84612 29.0881 4.05139L27.5702 5.62183C27.4939 5.70075 27.4582 5.81042 27.4736 5.9191L27.7785 8.08185C27.8184 8.36454 27.5261 8.57689 27.2696 8.45162L25.3069 7.49325C25.2083 7.4451 25.093 7.4451 24.9944 7.49325L23.0317 8.45162C22.7752 8.57689 22.4829 8.36454 22.5228 8.08185L22.8277 5.9191C22.843 5.81042 22.8074 5.70075 22.7311 5.62183L21.2132 4.05139C21.0148 3.84612 21.1264 3.50252 21.4076 3.45308L23.5587 3.07479C23.6668 3.05578 23.7601 2.988 23.8116 2.89108L24.8361 0.962115Z" fill="#1967FF" />
                            <path d="M35.0968 0.962115C35.2307 0.709982 35.592 0.709982 35.7259 0.962116L36.7504 2.89108C36.8019 2.988 36.8952 3.05578 37.0033 3.07479L39.1545 3.45308C39.4356 3.50252 39.5473 3.84612 39.3489 4.05139L37.8309 5.62183C37.7546 5.70075 37.719 5.81042 37.7343 5.9191L38.0393 8.08185C38.0791 8.36454 37.7869 8.57689 37.5303 8.45162L35.5677 7.49325C35.469 7.4451 35.3537 7.4451 35.2551 7.49325L33.2924 8.45162C33.0359 8.57689 32.7436 8.36454 32.7835 8.08185L33.0885 5.9191C33.1038 5.81042 33.0682 5.70075 32.9919 5.62183L31.4739 4.05139C31.2755 3.84612 31.3871 3.50252 31.6683 3.45308L33.8195 3.07479C33.9276 3.05578 34.0208 2.988 34.0723 2.89108L35.0968 0.962115Z" fill="#1967FF" />
                            <path d="M45.3566 0.962115C45.4905 0.709982 45.8518 0.709982 45.9857 0.962116L47.0102 2.89108C47.0617 2.988 47.155 3.05578 47.2631 3.07479L49.4142 3.45308C49.6954 3.50252 49.807 3.84612 49.6086 4.05139L48.0907 5.62183C48.0144 5.70075 47.9788 5.81042 47.9941 5.9191L48.299 8.08185C48.3389 8.36454 48.0466 8.57689 47.7901 8.45162L45.8274 7.49325C45.7288 7.4451 45.6135 7.4451 45.5149 7.49325L43.5522 8.45162C43.2957 8.57689 43.0034 8.36454 43.0433 8.08185L43.3482 5.9191C43.3636 5.81042 43.3279 5.70075 43.2516 5.62183L41.7337 4.05139C41.5353 3.84612 41.6469 3.50252 41.9281 3.45308L44.0792 3.07479C44.1873 3.05578 44.2806 2.988 44.3321 2.89108L45.3566 0.962115Z" fill="#1967FF" />
                        </svg>
                        <span>{date}</span>
                    </div>
                </div>
            </div>
            <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
    );
}

export default Review;
