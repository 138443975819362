import React from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import Review from '../components/Review/Review';
import CustomTitle from '../components/UI/title/CustomTitle';

const John = () => {
    return (
        <>
            <CustomTitle style={{ marginBottom: "24rem" }} title="1,500 items sold for $43K for a garden shop" />
            <Review style={{ marginBottom: "16rem" }} img="John.png" name="John Brown" date="2 weeks ago" text="In just two months, my revenue shot up from nothing to $43k, all thanks to great analytics. Using Zeely tools was a big help, giving me insights fast. I made creatives and started ads, but the real change came from using AI analytics. This AI taught me to better analyze and boost my ad performance."/>
            <div style={{ aspectRatio: "303/181", marginBottom: "32rem" }}>
                <img src={process.env.PUBLIC_URL + '/img/John-stat.webp'} alt="" />
            </div>

            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton buttonText="Continue" onClick={next}>Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            />
        </>
    );
}

export default John;
