import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import classes from './MyInput.module.scss';
import CustomRadio from '../radio/CustomRadio';

const MyInputCountry = ({ onChange, option, setEventProperties, sessionId, setEventName, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dataCountries, setDataCountries] = useState({
    "United States": [],
    "New York": [],
    "Florida": [],
    "California": [],
    "Maryland": [],
    "Texas": [],
    "New Jersey": [],
    "Ohio": [],
    "Virginia": [],
    "North Carolina": [],
    "Louisiana": [],
    "Arizona": [],
    "Colorado": [],
    "Atlanta": [],
    "Los Angeles": [],
    "Houston": [],
    "Miami": [],
    "Atlanta, Georgia": [],
    "Chicago": [],
    "Las Vegas": [],
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);


  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  const [companyAddress, setCompanyAddress] = useState('United States');



  function businessProfiles(value) {
    var profilesJSON = localStorage.getItem('business-profiles');
    if (profilesJSON) {
      var profiles = JSON.parse(profilesJSON);
      profiles.forEach(function (profile) {
        profile.companyAddress = value;
      });
      var updatedProfilesJSON = JSON.stringify(profiles);
      localStorage.setItem('business-profiles', updatedProfilesJSON);
    } else {
      var newProfile = {
        companyAddress: value
      };

     

      var newProfiles = [newProfile];
      var newProfilesJSON = JSON.stringify(newProfiles);
      localStorage.setItem('business-profiles', newProfilesJSON);
    }
  }


  useEffect(() => {
    const storedCompanyAddressValue = localStorage.getItem('country');
    if (storedCompanyAddressValue) {
      setCompanyAddress(storedCompanyAddressValue);
      businessProfiles(storedCompanyAddressValue)
      if(setEventProperties) {
        setEventProperties({
          session_id: sessionId,
          web_entrance: 'signup',
          web_location_answer: storedCompanyAddressValue
      })
      }
      
    } else {
      businessProfiles('United States')
      localStorage.setItem('country', 'United States');
      if(setEventProperties) {
        setEventProperties({
          session_id: sessionId,
          web_entrance: 'signup',
          web_location_answer: 'United States'
      })
      }
    
    }
    setCountries(searchCountry('', dataCountries))
    if(setEventName) {
      setEventName('web_location')
    }
  
 
  }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (containerRef.current && !containerRef.current.contains(event.target)) {
  //       setIsActive(false);
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [containerRef]);

  const searchCountry = (searchText, dataCountries) => {
    return Object.keys(dataCountries).filter(country =>
      country.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const searchCity = (data, searchText) => {
    let results = [];

    Object.keys(dataCountries).forEach(country => {
      const filteredCities = data[country].filter(city =>
        city.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredCities.length > 0) {
        results = results.concat({ country, cities: [filteredCities[0]] });
      }

    });

    return results;
  };




  useEffect(() => {
    axios
      .get('./data/country-new.json')
      .then((res) => {
        setDataCountries(res.data);
        setCountries(searchCountry('', res.data))
      })
      .catch(err => console.log(err));
  }, []);



  const handleChange = event => {
    setSearchTerm(event.target.value);
    onChange(event.target.value);
    setCities(searchCity(dataCountries, event.target.value));
    setCountries(searchCountry(event.target.value, dataCountries));
    setIsActive(true);
  };

  const handleOptionClick = option => {
    onChange(option);
    setSearchTerm(option);
    setIsActive(false);
  };

  function arrowClick() {
    setIsActive(true);
    setCities(searchCity(dataCountries, ''));
    setCountries(searchCountry('', dataCountries));
  }

  const [selectedRadio, setSelectedRadio] = useState('United States');

  const handleRadioClick = (value, nextFunction) => {
    setSelectedRadio(value);
    localStorage.setItem('country', value);
    businessProfiles(value)

    setEventProperties({
      session_id: sessionId,
      web_entrance: 'signup',
      web_location_answer: value
  })
  };

  return (
    <div className={`${classes.searchContainer} ${isActive ? classes.active : ''}`} ref={containerRef} >
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        className={classes.myInput}
        {...props}
      />
      <div className={classes.search}>
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8.5" r="6.35" stroke="black" stroke-width="1.3" />
          <path d="M12.5 13.5L15.5 16.5" stroke="black" stroke-width="1.3" stroke-linecap="round" />
        </svg>

      </div>
      {/* <div className={classes.arrow} onClick={arrowClick}>
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.41 5.78922L9 10.3692L13.59 5.78922L15 7.19922L9 13.1992L3 7.19922L4.41 5.78922Z" fill="#A7A8AA" />
        </svg>
      </div> */}

      {/* {isActive && (countries.length > 0 || cities.length > 0) ? ( */}
      <ul className={classes.countryList} style={{ display: 'flex', flexDirection: 'column' }}>

        {searchTerm != "" && (


          <CustomRadio
            checked={selectedRadio === searchTerm}
            onClick={() => handleRadioClick(searchTerm)}

            name='country'
            style={{ minHeight: '42rem' }}
          >
            <b>{searchTerm}</b>
          </CustomRadio>

        )}

        {countries.map((country, index) => {
          const parts = country.split(new RegExp(`(${searchTerm})`, 'gi'));
          return (
            <CustomRadio
              checked={selectedRadio === country}
              onClick={() => handleRadioClick(country)}
              key={index}
              name='country'
              // order: selectedRadio === country ? -1 : 0 
              style={{ minHeight: '42rem' }}
            >
              {parts.map((part, partIndex) =>
                part.toLowerCase() === searchTerm.toLowerCase() ? <b key={partIndex}>{part}</b> : part
              )}
            </CustomRadio>
          );
        })}
        {/* {cities.map((city, index) => {
            const parts = city.cities.join(', ').split(new RegExp(`(${searchTerm})`, 'gi'));
            return (
              <CustomRadio
                checked={selectedRadio === city.cities.join(', ')}
                onClick={() => handleRadioClick(city.cities.join(', '))}
                key={index}
                name='country'
                style={{ height: '42rem' }}
              >
                {parts.map((part, partIndex) =>
                  part.toLowerCase() === searchTerm.toLowerCase() ? <b key={partIndex}>{part}</b> : part
                )}
              </CustomRadio>
            );
          })}
          
          {cities.map((city, index) => (

            <CustomRadio checked={selectedRadio === city.country} onClick={() => handleRadioClick(city.country)} key={index} name='country' style={{ height: '42rem' }}>
              {city.cities.join(', ')}
            </CustomRadio>
          ))} */}
        {cities.map((city, index) => (
          <CustomRadio
            checked={selectedRadio === city.cities.join(', ') + city.country}
            onClick={() => handleRadioClick(city.cities.join(', ') + city.country)}
            key={index}
            name='country'
            style={{ minHeight: '42rem' }}
          >
            {city.cities.map((cityName, cityIndex) => {
              const parts = cityName.split(new RegExp(`(${searchTerm})`, 'gi'));
              let count = 0;
              // Подсчет количества вхождений текущего cityName
              cities.forEach(item => {
                item.cities.forEach(city => {
                  if (city === cityName) {
                    count++;
                  }
                });
              });
              return (
                <span key={cityIndex}>
                  {parts.map((part, partIndex) =>
                    part.toLowerCase() === searchTerm.toLowerCase() ? <b key={partIndex}>{part}</b> : part
                  )}
                  {cityIndex < city.cities.length - 1 ? ', ' : ''}
                  {/* Отображение названия страны, если cityName встречается более одного раза */}
                  {count > 1 && (
                    <> ({city.country})</>
                  )}
                </span>
              );
            })}
          </CustomRadio>
        ))}
      </ul>
      {/* ) : (
        <CustomRadio name='country' checked={true} style={{ height: '42rem', marginTop: '12rem' }}>
          {companyAddress}
        </CustomRadio> */}
      {/* )} */}
    </div>
  );
};

export default MyInputCountry;