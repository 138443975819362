import React, { useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './better.scss';

const Better = ({ setButtonShow, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonShow(true);
        setEventName('web_employees')
        setEventProperties({
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            session_id: sessionId,
            user_id: localStorage.getItem('userId')
        })
    }, []);
    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle style={{ marginBottom: "24rem" }} title="AI will replace multiple employees and implement your plan" />

            <div className="better">
                <div className="better-item">
                    <div className="better-item-left">
                        <img src={process.env.PUBLIC_URL + '/img/b1.jpg'} alt="" />
                        <span>Digital Marketer</span>
                    </div>
                    <div className="better-item-right">
                        <span>AI Creatives </span>
                        <img src={process.env.PUBLIC_URL + '/img/better.logo.svg'} alt="" />
                    </div>
                </div>
                <div className="better-item">
                    <div className="better-item-left">
                        <img src={process.env.PUBLIC_URL + '/img/b2.jpg'} alt="" />
                        <span>Acquisition Manager</span>
                    </div>
                    <div className="better-item-right">
                        <span>AI Ads</span>
                        <img src={process.env.PUBLIC_URL + '/img/better.logo.svg'} alt="" />
                    </div>
                </div>
                <div className="better-item">
                    <div className="better-item-left">
                        <img src={process.env.PUBLIC_URL + '/img/b3.jpg'} alt="" />
                        <span>Copywriter</span>
                    </div>
                    <div className="better-item-right">
                        <span>AI Content </span>
                        <img src={process.env.PUBLIC_URL + '/img/better.logo.svg'} alt="" />
                    </div>
                </div>
                <div className="better-item">
                    <div className="better-item-left">
                        <img src={process.env.PUBLIC_URL + '/img/b4.jpg'} alt="" />
                        <span>Web Developer</span>
                    </div>
                    <div className="better-item-right">
                        <span>AI Sales Page</span>
                        <img src={process.env.PUBLIC_URL + '/img/better.logo.svg'} alt="" />
                    </div>
                </div>
                <div className="better-item">
                    <div className="better-item-left">
                        <img src={process.env.PUBLIC_URL + '/img/b5.jpg'} alt="" />
                        <span>Analyst</span>
                    </div>
                    <div className="better-item-right">
                        <span>AI Analytics</span>
                        <img src={process.env.PUBLIC_URL + '/img/better.logo.svg'} alt="" />
                    </div>
                </div>
              
            </div>
        </div>
    );
}

export default Better;
