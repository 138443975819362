import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Wizard, Steps, Step, WithWizard } from 'react-albus-react18';
import Home from "./pages/Home";
import { Line } from 'rc-progress';
import { Helmet } from "react-helmet";
import { gsap } from 'gsap';
import axios from 'axios';
import Do from "./pages/Do";
import Featured from "./pages/Featured/Featured";
import Channels from "./pages/Channels";
import Ai from "./pages/Ai";
import Sell from "./pages/Sell";
import Launch from "./pages/Launch";
import Depends from "./pages/Depends";
import BeforeAfter from "./pages/BeforeAfter";
import Visual from "./pages/Visual";
import Ai2 from "./pages/Ai/Ai";
import Statement from "./pages/Statement";
import Ai3 from "./pages/Ai3";
import Megan from "./pages/Case";
import Built from "./pages/Built";
import Personal from "./pages/Personal";
import Choose1 from "./pages/Choose1";
import Choose3 from "./pages/Choose3";
import Choose2 from "./pages/Choose2";
import Choose4 from "./pages/Choose4";
import Knowledge from "./pages/Knowledge";
import BeforeAfter2 from "./pages/BeforeAfter2";
import Statement2 from "./pages/Statement2";
import Ai4 from "./pages/Ai4";
import Analyze from "./pages/Analyze";
import Boosts from "./pages/Boosts";
import Statement3 from "./pages/Statement3";
import BeforeAfter3 from "./pages/BeforeAfter3";
import John from "./pages/John";
import Delia from "./pages/Delia";
import Strategy from "./pages/Strategy";
import Orders from "./pages/Orders.jsx/Orders";
import Reach from "./pages/Reach";
import Country from "./pages/Country";
import Summary from "./pages/Summary/Summary";
import Reveal from "react-awesome-reveal";
import AnimateHeight from 'react-animate-height';
import { keyframes } from "@emotion/react";
import Generate from './pages/Generate/Generate';
import MyButton from './components/UI/button/MyButton';
import GoogleSingUp from './pages/GoogleSingUp/GoogleSingUp';
import Signup from './pages/Signup/Signup';
import Name from './pages/Name';
import Predict from './pages/Predict';
import Better from './pages/Better/Better';
import Works from './pages/Works/Works';
import Price from './pages/Price/Price';
import Trial from './pages/Trial/Trial';
import Bundle from './pages/Bundle/Bundle';
import Logo from './components/Logo/Logo';
import Graph from './pages/Graph';
import Downgrade from './pages/Downgrade';
import Congrats from './pages/Congrats';
import HaveWebsite from './pages/HaveWebsite';
import Website from './pages/Website';
import BusinessArea from './pages/BusinessArea';
import BrandName from './pages/BrandName';
import Describe from './pages/Describe';
import Connection from './pages/Connection/Connection';
import Welcome from './pages/Welcome';
import Orders2 from './pages/Orders.jsx/Orders2';
import Strategy1 from './pages/Strategy/Strategy1';
import Strategy2 from './pages/Strategy/Strategy2';
import Strategy3 from './pages/Strategy/Strategy3';
import Benefits from './pages/Benefits/Benefits';
import Platform from './pages/Platform';
import Built1 from './pages/Built1';
import CustomCheckbox from './components/UI/chekbox/CustomCheckbox';
import Term from './pages/Term';
import Privacy from './pages/Privacy';
import { useHistory, useLocation } from 'react-router-dom';
import WebsiteType from './pages/WebsiteType';
import Inspiration from './pages/Inspiration';
import Revenue from './pages/Revenue';
import Create from './pages/Create';
import Videos from './pages/Videos';
import Making from './pages/Making';
import ChooseVideo from './pages/ChooseVideo';
import Revenue2 from './pages/Revenue2';
import Metrics from './pages/Metrics';
import Tracking from './pages/Tracking';
import Testing from './pages/Testing';
import Built2 from './pages/Built2';
import Connection2 from './pages/Connection/Connection2';
import Knowledge2 from './pages/Knowledge2';
import Goal from './pages/Goal';
import { Link } from '@mui/material';
import Plan from './pages/Plan/Plan';
import LogoDark from './components/Logo/LogoDark';
import WelcomeToZeely from './pages/WelcomeToZeely/WelcomeToZeely';
import CustomTitle from './components/UI/title/CustomTitle';
import LogoGrowth from './components/Logo/Logogrowth';
import Signup2 from './pages/Signup/Signup2';
import CheckEmail from './components/CheckEmail/CheckEmail';
import Case from './pages/Case';
import BackHistory from './components/BackHistory/BackHistory';
import ReactPlayer from 'react-player';
import TermPage from './pages/TermPage';
import PrivacyPage from './pages/PrivacyPage';
import mixpanel from 'mixpanel-browser';
import Home1 from './pages/Home1/Home1';
import Home2 from './pages/Home1/Home2';
import Home3 from './pages/Home1/Home3';
import Page5 from './pages/Home1/Home4';
import Page2 from './pages/Home1/Home5';
import Page6 from './pages/Home1/Home6';
import Page7 from './pages/Home1/Home7';
import Start from './pages/Home1/Start';

function App() {

    function setSessionIdLocalStorage(sessionId) {
        localStorage.setItem('session_id', sessionId);
    }

    function getSessionIdFromLocalStorage() {
        return localStorage.getItem('session_id');
    }

    function generateSessionId() {
        return Math.random().toString(36).substring(2);
    }

    const sessionId = getSessionIdFromLocalStorage() || generateSessionId();
    setSessionIdLocalStorage(sessionId);




    useEffect(() => {
        mixpanel.init('e777f1e7a8a69795e4b701389d9859a7', { ignore_dnt: true, debug: true, track_pageview: false, persistence: 'localStorage' });
        mixpanel.identify(sessionId)

        const isFirstSession = !localStorage.getItem('mixpanel_first_session');

        if (isFirstSession) {

            const userAgent = navigator.userAgent;
            const deviceType = detectDeviceType(userAgent);

            const installationDate = new Date();


            const formattedInstallationDate = formatDate(installationDate);

            localStorage.setItem('installation_date', formattedInstallationDate);

            mixpanel.track('SESSION_STARTED', {
                session_id: sessionId,
                // device_type: deviceType,
                session_start: formattedInstallationDate,
                // installation_source: window.location.hostname,
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
                funnel: 'MAIN_v1',
            });

            localStorage.setItem('mixpanel_first_session', 'true');
        }

    }, []);


    const formatDate = (date) => {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return `${month}.${day}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const detectDeviceType = (userAgent) => {
        // Example detection logic (you may need to adjust this based on your requirements)
        if (/Android/i.test(userAgent)) {
            return 'Android';
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            return 'iOS';
        } else if (/Windows Phone/i.test(userAgent)) {
            return 'Windows Phone';
        } else if (/Windows/i.test(userAgent)) {
            return 'Windows';
        } else if (/Macintosh|Mac OS/i.test(userAgent)) {
            return 'Macintosh';
        } else if (/Linux/i.test(userAgent)) {
            return 'Linux';
        } else {
            return 'Unknown';
        }
    };

    function saveUTMToLocalStorage() {
        const queryParams = new URLSearchParams(window.location.search);
        const utmSource = queryParams.get('utm_source');
        const utmMedium = queryParams.get('utm_medium');
        const utmCampaign = queryParams.get('utm_campaign');
        const utmContent = queryParams.get('utm_content');
        const utmTerm = queryParams.get('utm_term');

        if (utmSource) localStorage.setItem('utmSource', utmSource);
        if (utmMedium) localStorage.setItem('utmMedium', utmMedium);
        if (utmCampaign) localStorage.setItem('utmCampaign', utmCampaign);
        if (utmContent) localStorage.setItem('utmContent', utmContent);
        if (utmTerm) localStorage.setItem('utmTerm', utmTerm);
    }

    useEffect(() => {
        saveUTMToLocalStorage();
    }, []);

    const history = useHistory();
    const customAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;
    const [buttonText, setButtonText] = useState('Continue');
    const [buttonShow, setButtonShow] = useState(false);
    const [buttonTime, setButtonTime] = useState(0);
    const [buttonChoose, setButtonChoose] = useState(false);
    const [creativeId, setCreativeId] = useState('');
    const [creativeType, setCreativeType] = useState('');
    const [buttonStatus, setButtonStatus] = useState('');
    const [loginChangeStatus, setLoginChangeStatus] = useState(false);
    const [buttonSkip, setButtonSkip] = useState(false);
    const nextPageButtonRef = useRef(null);
    const [aiShow, setAiShow] = useState(false);
    const [homeShow, sethomeShow] = useState(false);
    const [handShow, setHandShow] = useState(false);
    const [welcometoShow, setWelcometo] = useState(false);
    const [WelcomeWrapper, setWelcomeWrapper] = useState(false);
    const [showBrandName, setShowBrandName] = useState(false);
    const [showWebSite, setShowWebSite] = useState(false);

    const [timeLeft, setTimeLeft] = useState(30);
    const [isChecked, setIsChecked] = useState(true);
    const [showTerm, setShowTerm] = useState(false);
    const [showTermBundle, setShowTermBundle] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showBlock, setShowBlock] = useState(true);
    const [signupBottom, setSignupBottom] = useState('');
    const [priceBottomShow, setPriceBottomShow] = useState(false);

    const [muteStatus, setMuteStatus] = useState(true);

    const [blockHeight, setBlockHeight] = useState('auto');
    const [blockPaddingBottom, setBlockPaddingBottom] = useState('32rem');
    const [whiteWrapper, setWhiteWrapper] = useState(false);
    const blockHeightRef = useRef(null);



    const updateHeight = () => {
        if (blockHeightRef.current) {
            const newHeight = blockHeightRef.current.offsetHeight;
            setBlockHeight(newHeight);
        }
    };

    useEffect(() => {
        updateHeight();
        const observer = new MutationObserver(updateHeight);
        observer.observe(blockHeightRef.current, { attributes: true, childList: true, subtree: true });
        return () => {
            observer.disconnect();
        };
    }, []);

    const [checkboxes, setCheckboxes] = useState({
        Facebook: true,
    });
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('1');

    const areAllCheckboxesUnchecked = () => {
        return Object.values(checkboxes).every((isChecked) => !isChecked);
    };

    const areAllRadioUnchecked = () => {
        return selectedOption === null;
    };

    const [caseStatus, setCaseStatus] = useState(true);
    const [caseName, setCaseName] = useState('case1');
    useEffect(() => {
        if (localStorage.getItem('direction')) {
            if (localStorage.getItem('direction') == "SERVICE") {
                setCaseStatus(false)
                setCaseName('case2')
            } else {
                setCaseStatus(true)
                setCaseName('case1')
            }
        }
    }, [localStorage.getItem('direction')]);

    const forgetPassword = () => {
        const data = {
            email: localStorage.getItem('email')
        };

        axios.post("https://staging.zeely.link/users/forgot-password", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {

            })
            .catch(error => {

            });
    };

    const [eventProperties, setEventProperties] = useState([]);
    const [eventName, setEventName] = useState([]);

    const HomeElement = <Home setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonText={setButtonText} setButtonShow={setButtonShow} setButtonTime={setButtonTime} />
    const DoElement = <Do setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} sessionId={sessionId} setButtonShow={setButtonShow} setButtonTime={setButtonTime} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} />
    const FeaturedElement = <Featured setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonText={setButtonText} setCheckboxes={setCheckboxes} />
    const GoalElement = <Goal setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCheckboxes={setCheckboxes} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonTime={setButtonTime} />
    const ChannelsElement = <Channels setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setCheckboxes={setCheckboxes} checkboxes={checkboxes} setSelectedOption={setSelectedOption} setError={setError} error={error} setButtonText={setButtonText} setButtonShow={setButtonShow} />
    const AiElement = <Ai setButtonText={setButtonText} setSelectedOption={setSelectedOption} />
    const SellElement = <Sell setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonTime={setButtonTime} />
    const [WebsiteStatus, setWebsiteStatus] = useState(false);
    const HaveWebsiteElement = <HaveWebsite setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonTime={setButtonTime} setSelectedOption={setSelectedOption} setButtonText={setButtonText} setWebsiteStatus={setWebsiteStatus} />
    const PlatformElement = <Platform setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonTime={setButtonTime} />
    const WebsiteTypeElement = <WebsiteType setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonTime={setButtonTime} />

    useEffect(() => {
        if (localStorage.getItem('haveWebsite')) {
            if (localStorage.getItem('haveWebsite') == "No") {
                setWebsiteStatus(false)
            }
            if (localStorage.getItem('haveWebsite') == "Yes") {
                setWebsiteStatus(true)
            }
        }
    }, []);





    const handleClickAi = () => {
        if (nextPageButtonRef.current) {
            // nextPageButtonRef.current.click();
        }
    };


    const Ai2Element =
        <Ai2
            videoShowStatus={true}
            timingInterval={[30, 30, 40]}
            videoSrc="shopify_shop_flow_mockup_compressed.m3u8"
            videoImg="Shopify shop flow mockup cover_compressed.webp"
            nextPage="/haveyouever1" handleClickAi={handleClickAi} aiText={WebsiteStatus ? [
                ['See how easy it is to set', " up\u00A0your account on Zeely"],
                ["Just connect your store in", 'a few clicks'],
                ["And select products or", "services to promote"],
            ] : [["See how easy it is to make", 'a site with AI on Zeely'],
            ['AI assists you in adding', "your products or services"],
            ["And setting up payments", 'to earn from sales']
            ]
            }
            eventName={'web_demosite'}
            setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
            setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />
    const Ai2NoElement =
        <Ai2 videoShowStatus={true}
            timingInterval={[70, 50, 40]}
            videoSrc="show_flow_mockup_compressed.m3u8"
            videoImg="shop flow mockup cover_compressed.webp"
            nextPage="/haveyouever1" handleClickAi={handleClickAi} aiText={WebsiteStatus ? [
                ['See how easy it is to set', " up\u00A0your account on Zeely"],
                ["Just connect your store in", 'a few clicks'],
                ["And select products or", "services to promote"],
            ] : [["See how easy it is to make", 'a site with AI on Zeely'],
            ['AI assists you in adding', "your products or services"],
            ["And setting up payments", 'to earn from sales']
            ]
            }
            eventName={'web_demosite'}
            setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
            setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />




    const LaunchElement = <Launch setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} setButtonTime={setButtonTime} setSelectedOption={setSelectedOption} />
    const DependsElement = <Depends setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} />
    const BeforeafterElement = <BeforeAfter setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} setCheckboxes={setCheckboxes} setSelectedOption={setSelectedOption} />
    const Built1Element = <Built1 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCheckboxes={setCheckboxes} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />
    const VisualElement = <Visual setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setSelectedOption={setSelectedOption} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} setCheckboxes={setCheckboxes} checkboxes={checkboxes} setError={setError} error={error} setButtonText={setButtonText} setButtonShow={setButtonShow} />
    const StatementElement = <Statement setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />
    const InspirationElement = <Inspiration setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonChoose={setButtonChoose} setCheckboxes={setCheckboxes} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />
    const Choose1Element = <Choose1 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCreativeType={setCreativeType} setCreativeId={setCreativeId} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />
    const Choose2Element = <Choose2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCreativeType={setCreativeType} setCreativeId={setCreativeId} setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} />
    const Choose3Element = <Choose3 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCreativeType={setCreativeType} setCreativeId={setCreativeId} setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} />
    const Choose4Element = <Choose4 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCreativeType={setCreativeType} setCreativeId={setCreativeId} setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} />
    const Ai3Element = <Ai3 setButtonShow={setButtonShow} setButtonText={setButtonText} />
    const MeganElement = <Megan setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />

    const RevenueElement = <Revenue setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} setButtonTime={setButtonTime} setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setSelectedOption={setSelectedOption} />

    const Strategy1Element = <Strategy1 setButtonChoose={setButtonChoose} setButtonShow={setButtonShow} setButtonText={setButtonText} />
    const Strategy2Element = <Strategy2 setButtonShow={setButtonShow} setButtonText={setButtonText} />
    const Strategy3Element = <Strategy3 setButtonShow={setButtonShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} />

    const VideosElement = <Videos setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setCheckboxes={setCheckboxes} setButtonShow={setButtonShow} setButtonText={setButtonText} />
    const MakingElement = <Making setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setSelectedOption={setSelectedOption} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setAiShow={setAiShow} setCheckboxes={setCheckboxes} checkboxes={checkboxes} setError={setError} error={error} setButtonText={setButtonText} setButtonShow={setButtonShow} />
    const KnowledgeElement = <Knowledge setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} />
    const ChooseVideo1Element = <ChooseVideo eventName={'web_example_1'} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} creativeId={'creativeId5'} setCreativeId={setCreativeId} setCreativeType={setCreativeType} muteStatus={muteStatus} setMuteStatus={setMuteStatus} videoCV='Customer_review.mp4' imgCV='Customer_review.webp' textCV1='Customer review' textCV2="Source: Foreplay" setButtonTime={setButtonTime} setButtonChoose={setButtonChoose} setButtonShow={setButtonShow} />
    const ChooseVideo2Element = <ChooseVideo eventName={'web_example_2'} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} creativeId={'creativeId6'} setCreativeId={setCreativeId} setCreativeType={setCreativeType} muteStatus={muteStatus} setMuteStatus={setMuteStatus} videoCV='Product_demo.mp4' imgCV='Product_demo.webp' textCV1='Product & service demo' textCV2="Source: Visp" setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} />
    const ChooseVideo3Element = <ChooseVideo eventName={'web_example_3'} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} creativeId={'creativeId7'} setCreativeId={setCreativeId} setCreativeType={setCreativeType} muteStatus={muteStatus} setMuteStatus={setMuteStatus} videoCV='Unpacking.mp4' imgCV='Unpacking.webp' textCV1='Product unboxing' textCV2="Source: Truly Grounded" setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} />
    const ChooseVideo4Element = <ChooseVideo eventName={'web_example_4'} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} creativeId={'creativeId8'} setCreativeId={setCreativeId} setCreativeType={setCreativeType} muteStatus={muteStatus} setMuteStatus={setMuteStatus} videoCV='Lifestyle.mp4' imgCV='Lifestyle.webp' textCV1='Lifestyle' textCV2="Source: Ryze" setButtonShow={setButtonShow} setButtonChoose={setButtonChoose} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setBlockPaddingBottom={setBlockPaddingBottom} />
    const Revenue2Element = <Revenue2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setSelectedOption={setSelectedOption} setButtonTime={setButtonTime} />

    const Beforeafter2Element = <BeforeAfter2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonTime={setButtonTime} setButtonShow={setButtonShow} setButtonText={setButtonText} />

    const OrdersElement = <Orders setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} setButtonText={setButtonText} buttonStatus={buttonStatus} />
    const Orders2Element = <Orders2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setButtonTime={setButtonTime} />
    const ReachElement = <Reach setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setButtonTime={setButtonTime} />;
    const CountryElement = <Country setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} />
    const SummaryElement = <Summary setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonStatus={setButtonStatus} />
    const SignupElement = <Signup setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setTimeLeft={setTimeLeft} forgetPassword={forgetPassword} setLoginChangeStatus={setLoginChangeStatus} setSelectedOption={setSelectedOption} setError={setError} error={error} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setButtonShow={setButtonShow} setButtonText={setButtonText} signupBottom={signupBottom} setSignupBottom={setSignupBottom} />
    const NameElement = <Name error={error} setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonStatus={setButtonStatus} setSignupBottom={setSignupBottom} setSelectedOption={setSelectedOption} />

    const SignupElement2 = <Signup2 setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} sessionId={sessionId} setShowBrandName={setShowBrandName} setShowWebSite={setShowWebSite} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} setLoginChangeStatus={setLoginChangeStatus} setSelectedOption={setSelectedOption} setError={setError} error={error} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} setBlockPaddingBottom={setBlockPaddingBottom} setButtonShow={setButtonShow} setButtonText={setButtonText} signupBottom={signupBottom} setSignupBottom={setSignupBottom} />


    const Case1Element = <Case
        title={'“I got 800+ new orders”'}
        name={'Scarlette Goff'}
        date={'4 weeks ago'}
        text={"My sales conversions have increased by 340%, all thanks to Zeely. The viral AI creatives are smashing it. I'm swamped with hoodie orders –800+ new orders and I just can't keep up"}
        img={'case1.png'}
        stat={'case1-stat.webp'}
        eventName={'web_usecase_1'}

        setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />

    const Case2Element = <Case
        title={"“I've got 500+ new clients”"}
        name={'Phoebe Holden'}
        date={'2 weeks ago'}
        text={"In just three months with Zeely, I've got 500+ new clients for my services. I didn't hire a single outside expert, just used creatives with high CTR and launched some killer ad campaigns on IG."}
        img={'case2.png'}
        stat={'case2-stat.webp'}
        eventName={'web_usecase_1'}
        setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />
    const Case3Element = <Case
        title={"“50% increase in clients”"}
        name={'William Murray'}
        date={'1 week ago'}
        text={"Before, my ad spend was bringing in 40-50 sales, which was pretty solid. But once I added AI videos, I got a 50% increase in clients almost overnight. Conversions simply exploded."}
        img={'case3.png'}
        stat={'case3-stat.webp'}
        eventName={'web_usecase_2'}
        setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />
    const Case4Element = <Case
        title={"“239% growth in 3 months”"}
        name={'Ellis Spencer'}
        date={'1 month ago'}
        text={"Growing my sales by 239% in 3 months taught me one big lesson: don't put all your eggs in one basket. To really nail your promotions, mix static and video creatives with killer ad campaigns."}
        img={'case4.png'}
        stat={'case4-stat.webp'}
        eventName={'web_usecase_3'}
        setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime} />
    const Case5Element = <Case
        title={"“I overperformed my goal”"}
        name={'Mark V. Powell'}
        date={'4 weeks ago'}
        text={`Got into Zeely with a goal that seemed way out there. Just stuck with it, rolled with the plan, played around with the AI tools, and bam! I overperformed my goal."`}
        img={'case5.png'}
        stat={'case5-stat.webp'}
        eventName={'web_usecase_4'}
        setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        setButtonShow={setButtonShow} setButtonText={setButtonText} setButtonChoose={setButtonChoose} setButtonTime={setButtonTime}
        setSignupBottom={setSignupBottom}  setButtonStatus={setButtonStatus}
        />

    const PriceElement = <Price setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setPriceBottomShow={setPriceBottomShow} setButtonShow={setButtonShow} setButtonText={setButtonText} setWhiteWrapper={setWhiteWrapper} />;
    const [trialLoad, setTrialLoad] = useState();
    const TrialElement = <Trial setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setTrialLoad={setTrialLoad} setShowTermBundle={setShowTermBundle} setButtonShow={setButtonShow} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} />;
    


    const stepsMain = [
        // { id: '', component: <Start setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        // { id: '', component: HomeElement, preload: DoElement },
        // { id: 'stage', component: DoElement, preload: "" },
        // { id: 'helped', component: FeaturedElement, preload: GoalElement },
        // { id: 'goals', component: GoalElement, preload: ChannelsElement },
        // { id: 'channels', component: ChannelsElement, preload: SellElement },
        // { id: 'products', component: SellElement, preload: caseStatus ? Case1Element : Case2Element },
        // { id: 'usecase1', component: caseStatus ? Case1Element : Case2Element, preload: "" },
        // { id: 'website', component: HaveWebsiteElement, preload: <>{PlatformElement}{WebsiteTypeElement}</> },
        // { id: 'platform', component: WebsiteStatus ? PlatformElement : WebsiteTypeElement, preload: <img src={process.env.PUBLIC_URL + '/img/ainew.webp'} alt="" /> },
        // { id: 'demosite', component: WebsiteStatus ? Ai2Element : Ai2NoElement, preload: '' },
        // { id: 'haveyouever1', component: LaunchElement, preload: "" },
        // { id: 'adsucces', component: DependsElement, preload: BeforeafterElement },
        // { id: 'creatives', component: BeforeafterElement, preload: "" },
        // { id: 'haveyouever2', component: Built1Element, preload: "" },
        // { id: 'difficult1', component: VisualElement, preload: "" },
        // { id: 'overspending', component: StatementElement, preload: "" },
        // { id: 'inspiration', component: InspirationElement, preload: Choose1Element },
        // { id: 'format1', component: Choose1Element, preload: Choose2Element },
        // { id: 'format2', component: Choose2Element, preload: Choose3Element },
        // { id: 'format3', component: Choose3Element, preload: Choose4Element },
        // { id: 'format4', component: Choose4Element, preload: RevenueElement },
        // {
        //     id: 'democreatives',
        //     component:
        //         <Ai2
        //             videoShowStatus={true}
        //             timingInterval={[35, 45, 100]}
        //             videoSrc="creative_builder_flow_to_web_3.m3u8"
        //             videoImg="ai2-1.webp"
        //             nextPage="/mixing" aiText={[
        //                 ["AI builds creatives without", "marketers and designers"],
        //                 ["Choose a high-converting", "template from our library"],
        //                 ["And AI will fill it with high-", "performing\u00A0images\u00A0&\u00A0texts"]
        //             ]}
        //             setButtonTime={setButtonTime}
        //             setBlockPaddingBottom={setBlockPaddingBottom}
        //             setButtonChoose={setButtonChoose}
        //             eventName={'web_democreatives'}
        //             setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        //             setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />,
        //     preload: ''
        // },

        // { id: 'mixing', component: RevenueElement, preload: Case3Element },
        // { id: 'usecase2', component: Case3Element, preload: "" },
        // { id: 'haveyouever3', component: <Create setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} setButtonTime={setButtonTime} setSelectedOption={setSelectedOption} />, preload: VideosElement },
        // { id: 'videos', component: VideosElement, preload: "" },
        // { id: 'difficult2', component: MakingElement, preload: "" },

        // {
        //     id: 'minimalcost', component: KnowledgeElement, preload:

        //         <img src={process.env.PUBLIC_URL + '/img/Customer_review.webp'} alt="" />
        // },

        // {
        //     id: 'example1', component: ChooseVideo1Element, preload:

        //         <img src={process.env.PUBLIC_URL + '/img/Product_demo.webp'} alt="" />
        // },
        // {
        //     id: 'example2', component: ChooseVideo2Element, preload:
        //         <img src={process.env.PUBLIC_URL + '/img/Unpacking.webp'} alt="" />

        // },
        // {
        //     id: 'example3', component: ChooseVideo3Element, preload:
        //         <img src={process.env.PUBLIC_URL + '/img/Lifestyle.webp'} alt="" />
        // },
        // { id: 'example4', component: ChooseVideo4Element, preload: '' },
        // {
        //     id: 'demovideos',
        //     component:
        //         <Ai2
        //             videoShowStatus={true}
        //             timingInterval={[30, 30, 30, 30]}
        //             videoSrc="video_builder_plug.m3u8"
        //             videoImg="video_builder 1.webp"
        //             nextPage="/analyze" aiText={[
        //                 ["See how AI creates viral &", "effective video creatives"],
        //                 ["Choose a product to", "promote or add a URL"],
        //                 ["Select an AI avatar and an", "engaging template"],

        //                 ["Receive a ready-made,", "sales-boosting video"],
        //             ]}
        //             eventName={'web_demovideos'}
        //             setButtonTime={setButtonTime}
        //             setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        //             setButtonChoose={setButtonChoose} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />,
        //     preload: ''
        // },



        // { id: 'analyze', component: <Metrics setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonChoose={setButtonChoose} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} setButtonTime={setButtonTime} setSelectedOption={setSelectedOption} />, preload: Beforeafter2Element },
        // { id: 'analytics', component: Beforeafter2Element, preload: "" },
        // // { id: 'tracking', component: <Tracking setButtonShow={setButtonShow} setButtonTime={setButtonTime} />, preload: "" },
        // { id: 'whatworks', component: <Knowledge2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: '' },
        // { id: 'results', component: <Testing setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonTime={setButtonTime} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} />, preload: "" },
        // {
        //     id: 'demoanalytics',
        //     component:
        //         <Ai2
        //             videoShowStatus={true}
        //             timingInterval={[30, 40, 80, 50]}
        //             videoSrc="analytic_flow_mockup_compressed.m3u8"
        //             videoImg="analytic flow mockup cover_compressed.webp"


        //             nextPage="/includeads" aiText={[
        //                 ["See how AI tracks", "your creatives' metrics"],
        //                 ["AI predicts results for", "each template"],
        //                 ["AI conducts A/B tests to", "find best-selling creatives"],
        //                 ["And shares insights with", "you to fuel your growth"],
        //             ]}
        //             eventName={'web_demoanalytics'}
        //             setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        //             setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />,
        //     preload: Revenue2Element
        // },

        // { id: 'includeads', component: Revenue2Element, preload: Case4Element },
        // { id: 'usecase3', component: Case4Element, preload: "" },
        // { id: 'skills', component: <Statement2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: <BeforeAfter3 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} /> },
        // { id: 'ads', component: <BeforeAfter3 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} />, preload: '' },
        // { id: 'target', component: <Statement3 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: "" },
        // { id: 'managing', component: <Built2 setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setCheckboxes={setCheckboxes} setButtonText={setButtonText} setError={setError} error={error} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: "" },
        // {
        //     id: 'demoads',
        //     component:
        //         <Ai2
        //             videoShowStatus={true}
        //             timingInterval={[60, 70, 70, 40]}
        //             videoSrc="promotion_flow_mockup__compressed.m3u8"
        //             videoImg="promotion flow mockup cover_compressed.webp"
        //             nextPage="/industry" aiText={[
        //                 ["See how easy it is to", "launch online ads with AI"],
        //                 ["Add pre-generated AI", "static and video creatives"],
        //                 ["AI sets up a campaign", "and handles budgeting"],
        //                 ["AI runs tests to optimize", "ads & boost your results"],
        //             ]}
        //             eventName={'web_demoads'}
        //             setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId}
        //             setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonText={setButtonText} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />,
        //     preload: <BusinessArea setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} error={error} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} />
        // },
        // { id: 'analyze', component: <Analyze />, preload: <Boosts /> },
        // { id: 'boosts', component: <Boosts />, preload: <John /> },


        // { id: 'delia', component: <Delia />, preload: "" },
        // { id: 'strategy', component: <Strategy setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} setButtonShow={setButtonShow} />, preload: "" },

        // { id: 'industry', component: <BusinessArea setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} error={error} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} setBlockPaddingBottom={setBlockPaddingBottom} setButtonStatus={setButtonStatus} setAiShow={setAiShow} />, preload: <John /> },
        // { id: 'location', component: CountryElement, preload: "" },
        // { id: 'orders', component: OrdersElement, preload: "" },
        // { id: 'ordersgoal', component: Orders2Element, preload: "" },
        // { id: 'timeline', component: ReachElement, preload: "" },

        // { id: 'summary', component: SummaryElement, preload: <Generate setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} setSignupBottom={setSignupBottom} /> },
        // { id: 'loading', component: <Generate setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} setSignupBottom={setSignupBottom} />, preload: '' },
        // { id: 'googlesignup', component: <GoogleSingUp setButtonShow={setButtonShow} setBlockPaddingBottom={setBlockPaddingBottom} setSignupBottom={setSignupBottom} />, preload: <John /> },

        { id: '', component: SignupElement, preload: "" },
        { id: 'name', component: NameElement, preload: <Predict setSignupBottom={setSignupBottom} sessionId={sessionId} setButtonStatus={setButtonStatus} setButtonShow={setButtonShow} /> },
        // { id: 'prediction', component: <Predict setSignupBottom={setSignupBottom} sessionId={sessionId} setButtonStatus={setButtonStatus} setButtonShow={setButtonShow} />, preload: Case5Element },
        { id: 'usecase4', component: Case5Element, preload: <Better setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} /> },
        { id: 'employees', component: <Better setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} />, preload: <Plan setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} /> },
        { id: 'tools', component: <Plan setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonText={setButtonText} />, preload: <Benefits setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} /> },
        { id: 'comparison', component: <Benefits setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} />, preload: '' },
        // { id: 'works', component: <Works setButtonShow={setButtonShow} setButtonText={setButtonText} />, preload: '' },
        { id: 'price', component: "", preload: '' },
        { id: 'trial', component: "", preload: '' },
        { id: 'loader', component: <WelcomeToZeely setButtonShow={setButtonShow} setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} />, preload: '' },
        { id: 'booster', component: <Bundle setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setButtonShow={setButtonShow} setBlockPaddingBottom={setBlockPaddingBottom} setWelcometo={setWelcometo} setWhiteWrapper={setWhiteWrapper} />, preload: <Graph setShowTermBundle={setShowTermBundle} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} setBlockPaddingBottom={setBlockPaddingBottom} /> },
        { id: 'booster1', component: <Graph setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setBlockPaddingBottom={setBlockPaddingBottom} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} />, preload: <Downgrade setShowTermBundle={setShowTermBundle} setShowBrandName={setShowBrandName} setShowWebSite={setShowWebSite} setWelcomeWrapper={setWelcomeWrapper} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} setBlockPaddingBottom={setBlockPaddingBottom} /> },
        { id: 'booster2', component: <Downgrade setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setShowTermBundle={setShowTermBundle} setBlockPaddingBottom={setBlockPaddingBottom} setShowBrandName={setShowBrandName} setShowWebSite={setShowWebSite} setWelcomeWrapper={setWelcomeWrapper} setButtonShow={setButtonShow} setWhiteWrapper={setWhiteWrapper} />, preload: '' },
        // { id: 'congrats', component: <Congrats />, preload: <John /> },
        // { id: 'website', component: <Website setButtonShow={setButtonShow} />, preload: <John /> },

        // { id: 'brandname', component: <BrandName setButtonSkip={setButtonSkip} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: <John /> },
        // { id: 'describe', component: <Describe setButtonSkip={setButtonSkip} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: <Connection setBlockPaddingBottom={setBlockPaddingBottom} setButtonSkip={setButtonSkip} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} /> },
        // { id: 'connection', component: <Connection setBlockPaddingBottom={setBlockPaddingBottom} setButtonSkip={setButtonSkip} setSelectedOption={setSelectedOption} setButtonShow={setButtonShow} />, preload: '' },
        { id: 'brandname', component: <Welcome setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonStatus={setButtonStatus} setShowBrandName={setShowBrandName} BrandName={true} setShowWebSite={setShowWebSite} WebSite={false} setsetShowBrandName setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} setWhiteWrapper={setWhiteWrapper} />, preload: <John /> },
        { id: 'domain', component: <Welcome setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonStatus={setButtonStatus} setShowBrandName={setShowBrandName} BrandName={false} setShowWebSite={setShowWebSite} WebSite={true} setsetShowBrandName setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} setWhiteWrapper={setWhiteWrapper} />, preload: <John /> },
        { id: 'thankyou', component: <Welcome setEventName={setEventName} setEventProperties={setEventProperties} sessionId={sessionId} setButtonShow={setButtonShow} setButtonStatus={setButtonStatus} setShowBrandName={setShowBrandName} BrandName={false} setShowWebSite={setShowWebSite} WebSite={false} setWelcomeWrapper={setWelcomeWrapper} setBlockPaddingBottom={setBlockPaddingBottom} setWhiteWrapper={setWhiteWrapper} />, preload: <John /> },
        { id: 'signup2', component: SignupElement2, preload: '' },
        { id: 'term', component: <TermPage setWhiteWrapper={setWhiteWrapper} setShowBlock={setShowBlock} setButtonShow={setButtonShow} setShowTerm={setShowTerm} />, preload: '' },
        { id: 'privacy', component: <PrivacyPage setWhiteWrapper={setWhiteWrapper} setShowBlock={setShowBlock} setButtonShow={setButtonShow} setShowPrivacy={setShowPrivacy} />, preload: '' },
       
        { id: 'home1', component: <Home1  sessionId={sessionId} setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home2', component: <Home2  sessionId={sessionId} setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home3', component: <Home3  sessionId={sessionId} setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home4', component: <Page5  sessionId={sessionId} setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home5', component: <Page2  sessionId={sessionId}  setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home6', component: <Page6   sessionId={sessionId} setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
        { id: 'home7', component: <Page7  sessionId={sessionId}setButtonShow={setButtonShow} setHandShow={setHandShow} setBlockPaddingBottom={setBlockPaddingBottom} sethomeShow={sethomeShow} setButtonStatus={setButtonStatus} />, preload: '' },
    ];

    const [BusinessAreaHave, setBusinessAreaHave] = useState(1);



    useEffect(() => {
        // if (window.location.pathname === '/loader') {
        //     mixpanel.track("web_loader", {
        //         session_id: sessionId,
        //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
        //         user_id: localStorage.getItem('userId'),
        //         web_utm_campaign: localStorage.getItem("utmCampaign"),
        //         web_utm_source: localStorage.getItem("utmSource"),
        //         web_utm_medium: localStorage.getItem("utmMedium"),
        //         web_utm_content: localStorage.getItem("utmContent"),
        //         web_utm_term: localStorage.getItem("utmTerm"),
        //         isDebug: false,
        //         funnel: 'MAIN_v1',
        //     });
        // }
        // if (window.location.pathname === '/thankyou') {
        //     mixpanel.track('web_appdownload', {
        //         session_id: sessionId,
        //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
        //         user_id: localStorage.getItem('userId'),
        //         // web_type_brandname: webTypeBrandname,
        //         web_plan: localStorage.getItem('plan'),
        //         web_utm_campaign: localStorage.getItem("utmCampaign"),
        //         web_utm_source: localStorage.getItem("utmSource"),
        //         web_utm_medium: localStorage.getItem("utmMedium"),
        //         web_utm_content: localStorage.getItem("utmContent"),
        //         web_utm_term: localStorage.getItem("utmTerm"),
        //         isDebug: false,
        //         funnel: 'MAIN_v1',
        //     });
        //     const installationDate = new Date();

        //     const formattedInstallationDate = formatDate(installationDate);


        //     const sessionStart = localStorage.getItem('installation_date');
        //     const sessionEnd = new Date();
        //     const parts = sessionStart.split(/[. :]/);

        //     const sessionDurationSeconds = (sessionEnd - new Date(sessionStart)) / 1000;

        //     console.log(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5])
        //     const formattedSessionDuration = formatDuration(sessionDurationSeconds);

        //     mixpanel.track('SESSION_ENDED', {
        //         session_id: sessionId,
        //         web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
        //         user_id: localStorage.getItem('userId'),
        //         session_start: sessionStart,
        //         session_end: formattedInstallationDate,
        //         session_duration: formattedSessionDuration,
        //         web_utm_campaign: localStorage.getItem("utmCampaign"),
        //         web_utm_source: localStorage.getItem("utmSource"),
        //         web_utm_medium: localStorage.getItem("utmMedium"),
        //         web_utm_content: localStorage.getItem("utmContent"),
        //         web_utm_term: localStorage.getItem("utmTerm"),
        //         isDebug: false,
        //         funnel: 'MAIN_v1',
        //     });
        // }

        // console.log(window.location.pathname)

    }, [window.location])


    function formatDuration(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return {
            hours,
            minutes,
            seconds: remainingSeconds
        };
    }

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const termChange = () => {
        setShowBlock(false);
        setButtonShow(false)
        setShowTerm(true);
        setBlockPaddingBottom('0')
    };

    const privacyChange = () => {
        setShowBlock(false);
        setButtonShow(false)
        setShowPrivacy(true);
        setBlockPaddingBottom('0')
    };


    const termClose = () => {
        setShowBlock(true);
        setButtonShow(true)
        setShowTerm(false);
        setShowPrivacy(false);
        setBlockPaddingBottom('32rem')
    }

    const termBundleClose = () => {
        setShowTermBundle(false)
    }

    const loginChange = () => {
        setButtonStatus("loginChange")
    };



    const handleClickChoose = (nextFunction, clickedElement, id, value) => {

        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.creativesQuiz = profile.creativesQuiz || {};
                if (profile.creativesQuiz.hasOwnProperty(id)) {
                    profile.creativesQuiz[id] = { "type": creativeType, "checked": value };
                } else {
                    profile.creativesQuiz[id] = { "type": creativeType, "checked": value };
                }
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                creativesQuiz: {}
            };
            newProfile.creativesQuiz[id] = { "type": creativeType, "checked": value };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }


        const tl = gsap.timeline();
        tl.to(clickedElement, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            background: '#F2F4F6',
            ease: "power4.out",

        });
        tl.to(clickedElement, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            background: '#FFF',
            ease: "elastic.out(1,0.6)",
        });

        const preEventProperties = eventProperties;

        preEventProperties.web_utm_campaign = localStorage.getItem("utmCampaign");
        preEventProperties.web_utm_source = localStorage.getItem("utmSource");
        preEventProperties.web_utm_medium = localStorage.getItem("utmMedium");
        preEventProperties.web_utm_content = localStorage.getItem("utmContent");
        preEventProperties.web_utm_term = localStorage.getItem("utmTerm");
        preEventProperties.isDebug = false;
        preEventProperties.funnel = 'MAIN_v1';
        mixpanel.track(eventName, eventProperties);

        setTimeout(() => {
            nextFunction();
        }, 350);
    };



    const [businessProfilesUpdated, setBusinessProfilesUpdated] = useState(false);


    const [trialNext, setTrialNext] = useState();
    const [trialStatus, setTrialStatus] = useState(false);

    useEffect(() => {
        if (trialStatus) {
            if (trialLoad) {
                setButtonStatus('load');
            } else {

                if (typeof trialNext === 'function') {
                    setTimeout(() => {
                        setButtonStatus('');
                        setTrialStatus(false)
                        trialNext();
                    }, 300);

                }
            }
        }
    }, [trialLoad, trialStatus]);

    const handleClick = (next) => {

        if (buttonStatus == 'checkEmail') {
            setError(true);
            setButtonStatus('checkEmailLoad');
            return;
        }

        if (trialLoad === true) {
            setTrialStatus(true);
            setTrialNext(() => next);
            return;
        }


        if (buttonStatus == 'load') {
            return;
        }
        if (buttonStatus == 'signIn') {
            setButtonStatus('signInLoad');
            return;
        }
        if (buttonStatus == 'signUp') {
            setButtonStatus('signUpLoad');
            return;
        }


        if (buttonStatus == 'EnterEmail') {
            setButtonStatus('EnterEmailLoad');
            return;
        }
        if (buttonStatus == 'EnterCode') {
            setButtonStatus('EnterCodeLoad');
            return;
        }


        if (buttonStatus == 'NewPassword') {
            setButtonStatus('NewPasswordLoad');
            return;
        }

        if (buttonStatus === 'businessProfilesUpdate') {
            const webEntrance = localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup';
            mixpanel.track('web_name', {
                session_id: sessionId,
                web_entrance: webEntrance,
                user_id: localStorage.getItem('userId'),
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
                funnel: 'MAIN_v1',
            });
            next();
            businessProfilesUpdate();
            NameUpdate();
            setError(false);
            return;
        }

        if (buttonStatus == 'Summary') {
            setButtonStatus('SummaryLoad');
            return
        }

        if (areAllCheckboxesUnchecked() || areAllRadioUnchecked()) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1000);
        } else {
            setError(false);
            const preEventProperties = eventProperties;

            preEventProperties.web_utm_campaign = localStorage.getItem("utmCampaign");
            preEventProperties.web_utm_source = localStorage.getItem("utmSource");
            preEventProperties.web_utm_medium = localStorage.getItem("utmMedium");
            preEventProperties.web_utm_content = localStorage.getItem("utmContent");
            preEventProperties.web_utm_term = localStorage.getItem("utmTerm");
            preEventProperties.isDebug = false;
            preEventProperties.funnel = 'MAIN_v1';
            mixpanel.track(eventName, eventProperties);

            if (eventName === 'web_start') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'form_start'
                });
            }
            if (eventName === 'web_demosite') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'quiz_funnel1'
                });
            }
            if (eventName === 'web_democreatives') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'quiz_funnel2'
                });
            }
            if (eventName === 'web_demovideos') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'quiz_funnel3'
                });
            }
            if (eventName === 'web_loading') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'quiz_funnel4'
                });
            }
            next();
        }
    };



    const businessProfilesUpdate = () => {
        const fullJSON = localStorage.getItem('business-profiles');
        const profile = JSON.parse(fullJSON)[0];
        const trimmedJSON = JSON.stringify(profile);

        const data = trimmedJSON;
        if (localStorage.getItem('signupMethod') === 'Login') {

            axios.get("https://staging.zeely.link/business-profiles", {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                redirect: 'follow'
            })

                .then(response => {
                    axios.patch(`https://staging.zeely.link/business-profiles/${response.data.data.id}`, data, {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                        redirect: 'follow'
                    })
                        .then(response => {
                            localStorage.setItem('business-profiles-id', response.data.data.id);
                        })
                        .catch(error => {

                            console.log(error)
                        });
                })
                .catch(error => {
                    console.log(error)

                });



        } else {
            axios.post("https://staging.zeely.link/business-profiles", data, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                redirect: 'follow'
            })
                .then(response => {
                    localStorage.setItem('business-profiles-id', response.data.data.id);
                })
                .catch(error => {
                    console.log(error)
                });
        }

    };

    const NameUpdate = () => {
        const fullJSON = localStorage.getItem('business-profiles');
        const profile = JSON.parse(fullJSON)[0];
        const trimmedJSON = JSON.stringify({
            "name": profile.userName
        });

        const data = trimmedJSON

        axios.patch("https://staging.zeely.link/users", data, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                // localStorage.setItem('business-profiles-id', response.data.data.id);

            })
            .catch(error => {
                sendAlert("https://staging.zeely.link/users", data, error.response.data.message)
                console.log()
            });
    };

    const sendAlert = async (path, data, errorLog) => {
        const now = new Date();
        const utcString = now.toISOString();

        const formdata = new FormData();
        formdata.append("domain", window.location.hostname);
        formdata.append("time", utcString);
        formdata.append("email", localStorage.getItem('email'));
        formdata.append("path", path);
        formdata.append("data", data);
        formdata.append("error", errorLog);
        console.log(formdata)
        try {
            const response = await axios.post("https://zee.sale/send-alert.php", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };



    const handleForget = () => {
        // setTimeLeft(30);
        // forgetPassword();
        setButtonStatus("EnterEmail");
    };


    const handleForget22 = () => {
        setTimeLeft(30);
        forgetPassword();
        setButtonStatus("EnterCode");
    };




    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);




    useEffect(() => {

        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);



    return (

        <Router>
            <Route
                render={({ history }) => (
                    <Wizard history={history}
                        render={({ step, steps }) => (
                            <div className={`wrapper ${aiShow ? 'block-ai' : ''} ${homeShow ? 'block-home' : ''} ${whiteWrapper ? 'block-white' : ''} ${WelcomeWrapper ? 'block-welcome' : ''}`}>
                                <Helmet>
                                    <meta name="theme-color" content="#000"></meta>
                                </Helmet>
                                <>
                                    <WithWizard
                                        render={({ next, previous, step, steps }) => (
                                            <BackHistory sessionId={sessionId} step={step} nextFunction={next} />
                                        )}
                                    />
                                    {!aiShow && (

                                        (steps.indexOf(step) <= steps.findIndex(step => step.id === "signup2")) && (
                                            <div className="header">
                                                {(steps.indexOf(step) >= steps.findIndex(step => step.id === "booster")) && (
                                                    <div className="header-top">

                                                        {(steps.indexOf(step) > steps.findIndex(step => step.id === "thankyou")) ? (
                                                            <Logo />
                                                        ) : (
                                                            <LogoGrowth style={{ width: '151rem', height: '32rem' }} />
                                                        )}


                                                        {(steps.indexOf(step) == steps.findIndex(step => step.id === "booster")) && (
                                                            <WithWizard
                                                                render={({ next, previous, step, steps }) => (
                                                                    <div className="back" style={{ left: 'auto', right: '0', fontSize: '14rem', color: '#A7A8AA', fontWeight: '600', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            mixpanel.track('web_booster', {
                                                                                session_id: sessionId,
                                                                                web_level: 'TRIAL',
                                                                                web_plan__name: localStorage.getItem('plan'),
                                                                                web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                                                                                user_id: localStorage.getItem('userId'),
                                                                                web_utm_campaign: localStorage.getItem("utmCampaign"),
                                                                                web_utm_source: localStorage.getItem("utmSource"),
                                                                                web_utm_medium: localStorage.getItem("utmMedium"),
                                                                                web_utm_content: localStorage.getItem("utmContent"),
                                                                                web_utm_term: localStorage.getItem("utmTerm"),
                                                                                isDebug: false,
                                                                                funnel: 'MAIN_v1',
                                                                            });
                                                                            next()
                                                                        }}

                                                                    >
                                                                        Skip
                                                                    </div>
                                                                )}
                                                            />
                                                        )}
                                                        {(steps.indexOf(step) == steps.findIndex(step => step.id === "booster1")) && (
                                                            <WithWizard
                                                                render={({ next, previous, step, steps }) => (
                                                                    <div className="back" onClick={() => {
                                                                        mixpanel.track('web_booster_1', {
                                                                            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                                                                            session_id: sessionId,
                                                                            web_level: 'TRIAL',
                                                                            web_plan__name: localStorage.getItem('plan'),
                                                                            user_id: localStorage.getItem('userId'),
                                                                            web_utm_campaign: localStorage.getItem("utmCampaign"),
                                                                            web_utm_source: localStorage.getItem("utmSource"),
                                                                            web_utm_medium: localStorage.getItem("utmMedium"),
                                                                            web_utm_content: localStorage.getItem("utmContent"),
                                                                            web_utm_term: localStorage.getItem("utmTerm"),
                                                                            isDebug: false,
                                                                            funnel: 'MAIN_v1',
                                                                        });
                                                                        next()
                                                                    }}>

                                                                        <svg style={{ width: '30rem', height: '30rem' }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 9L21 21" stroke="#A7A8AA" stroke-width="2" stroke-linecap="round" />
                                                                            <path d="M21 9L9 21" stroke="#A7A8AA" stroke-width="2" stroke-linecap="round" />
                                                                        </svg>


                                                                    </div>
                                                                )}
                                                            />
                                                        )}
                                                        {(steps.indexOf(step) == steps.findIndex(step => step.id === "booster2")) && (
                                                            <WithWizard
                                                                render={({ next, previous, step, steps }) => (
                                                                    <div className="back" style={{ left: 'auto', right: '0', fontSize: '14rem', color: '#A7A8AA', fontWeight: '600', cursor: 'pointer' }} onClick={() => {
                                                                        mixpanel.track('web_booster_2', {
                                                                            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                                                                            session_id: sessionId,
                                                                            web_level: 'TRIAL',
                                                                            web_plan__name: localStorage.getItem('plan'),
                                                                            user_id: localStorage.getItem('userId'),
                                                                            web_utm_campaign: localStorage.getItem("utmCampaign"),
                                                                            web_utm_source: localStorage.getItem("utmSource"),
                                                                            web_utm_medium: localStorage.getItem("utmMedium"),
                                                                            web_utm_content: localStorage.getItem("utmContent"),
                                                                            web_utm_term: localStorage.getItem("utmTerm"),
                                                                            isDebug: false,
                                                                            funnel: 'MAIN_v1',
                                                                        });
                                                                        next()
                                                                    }}>
                                                                        <svg style={{ width: '30rem', height: '30rem' }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 9L21 21" stroke="#A7A8AA" stroke-width="2" stroke-linecap="round" />
                                                                            <path d="M21 9L9 21" stroke="#A7A8AA" stroke-width="2" stroke-linecap="round" />
                                                                        </svg>

                                                                    </div>
                                                                )}
                                                            />
                                                        )}

                                                    </div>
                                                )}

                                                {(steps.indexOf(step) < steps.findIndex(step => step.id === "booster")) && (
                                                    <div className="header-top">
                                                        {((steps.indexOf(step) < steps.findIndex(step => step.id === "booster")) && steps.findIndex(step => step.id === "signup") != steps.indexOf(step) && steps.findIndex(step => step.id === "loading") != steps.indexOf(step) && steps.findIndex(step => step.id === "name") != steps.indexOf(step) && steps.findIndex(step => step.id === "") != steps.indexOf(step) && steps.findIndex(step => step.id === "stage") != steps.indexOf(step)) && (
                                                            <>
                                                                <WithWizard
                                                                    render={({ next, previous, step, steps }) => (
                                                                        <div className="back" onClick={() => {
                                                                            if (buttonStatus === 'Summary') {
                                                                                setButtonStatus("");
                                                                                history.push('/summary');
                                                                            } else {
                                                                                previous();
                                                                            }
                                                                        }}>
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11 2L5 8L11 14" stroke={whiteWrapper ? "black" : "white"} strokeWidth="2.1" strokeLinecap="round" />
                                                                            </svg>
                                                                        </div>
                                                                    )}
                                                                />

                                                            </>
                                                        )}
                                                        {whiteWrapper ? <LogoDark /> : <Logo />}
                                                    </div>
                                                )}


                                                {steps.indexOf(step) < steps.findIndex(step => step.id === "booster") && !whiteWrapper && (
                                                    <div className="line">
                                                        <div className="line-item">
                                                            <i style={{ width: `100%` }}></i>
                                                        </div>
                                                        {/* <Line
                                                        percent={(steps.indexOf(step) + 0) / steps.findIndex(step => step.id === "platform") * 100}
                                                        className="pad-b"
                                                    /> */}
                                                        {steps.indexOf(step) > steps.findIndex(step => step.id === "platform") ? (
                                                            <div className="line-item">
                                                                <i style={{ width: `100%` }}></i>
                                                            </div>

                                                        ) : (
                                                            <div className="line-item">

                                                            </div>
                                                        )}
                                                        {steps.indexOf(step) > steps.findIndex(step => step.id === "format4") ? (
                                                            <div className="line-item">
                                                                <i style={{ width: `100%` }}></i>
                                                            </div>


                                                        ) : (
                                                            <div className="line-item">

                                                            </div>
                                                        )}
                                                        {steps.indexOf(step) > steps.findIndex(step => step.id === "example4") ? (

                                                            <div className="line-item">
                                                                <i style={{ width: `${(steps.indexOf(step) - steps.findIndex(step => step.id === "analyze")) / (steps.findIndex(step => step.id === "price") - steps.findIndex(step => step.id === "analyze")) * 100}%` }}></i>
                                                            </div>

                                                        ) : (
                                                            <div className="line-item">

                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </>
                                {steps.indexOf(step) > 0 && (
                                    <CheckEmail />
                                )}



                                <div
                                    className="block"
                                    style={{ paddingBottom: blockPaddingBottom }}
                                >   <AnimateHeight className="block-animate"
                                    duration={600}
                                    height={blockHeight}
                                >
                                        <div className="block-height"
                                            ref={blockHeightRef}
                                        >   {((steps.indexOf(step) === steps.findIndex(step => step.id === "price")) || (steps.indexOf(step) === steps.findIndex(step => step.id === "trial"))) && (
                                            <>
                                                <div
                                                    className={`${steps.indexOf(step) === steps.findIndex(step => step.id === "price") && 'preload'}`}
                                                >
                                                    {TrialElement}
                                                </div>
                                                {
                                                    steps.indexOf(step) === steps.findIndex(step => step.id === "price") && (
                                                        PriceElement
                                                    )
                                                }
                                            </>
                                        )}

                                            <Steps>
                                                {stepsMain.map(stepMain => (

                                                    <Step key={stepMain.id} id={stepMain.id}>

                                                        {((steps.indexOf(step) != steps.findIndex(step => step.id === "price")) && (steps.indexOf(step) != steps.findIndex(step => step.id === "trial"))) && (
                                                            <>
                                                                <div className="preload">{stepMain.preload}

                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "demosite")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "democreatives")) && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/ai2-1.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/creative_builder_flow_to_web_3.m3u8`} controls={false} />
                                                                        </>
                                                                    )}

                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "website")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "demosite")) && !WebsiteStatus && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/shop flow mockup cover_compressed.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/show_flow_mockup_compressed.m3u8`} controls={false} />
                                                                        </>
                                                                    )}

                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "website")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "demosite")) && WebsiteStatus && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/Shopify shop flow mockup cover_compressed.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/shopify_shop_flow_mockup_compressed.m3u8`} controls={false} />
                                                                        </>
                                                                    )}
                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "demovideos")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "demoanalytics")) && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/analytic flow mockup cover_compressed.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/analytic_flow_mockup_compressed.m3u8`} controls={false} />
                                                                        </>
                                                                    )}

                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "demoanalytics")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "demoads")) && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/promotion flow mockup cover_compressed.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/promotion_flow_mockup__compressed.m3u8`} controls={false} />
                                                                        </>
                                                                    )}

                                                                    {(steps.indexOf(step) > steps.findIndex(step => step.id === "democreatives")) && (steps.indexOf(step) < steps.findIndex(step => step.id === "demovideos")) && (
                                                                        <><img style={{ position: 'absolute', top: "0", left: '0' }} src={process.env.PUBLIC_URL + '/img/video_builder 1.webp'} alt="" />

                                                                            <ReactPlayer
                                                                                width='100%'
                                                                                height='auto'
                                                                                loop={true}
                                                                                playing={true}
                                                                                playsinline={true}
                                                                                volume={0.5}
                                                                                muted={true}
                                                                                url={process.env.PUBLIC_URL + `/video/video_builder_plug.m3u8`} controls={false} />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {showBlock && (
                                                                    !welcometoShow && (
                                                                        <Reveal cascade keyframes={customAnimation}>
                                                                            {stepMain.component}
                                                                        </Reveal>
                                                                    )
                                                                )}
                                                                {showTerm && (
                                                                    <>
                                                                        <div className="term-back" onClick={termClose}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                                                                <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <Term></Term>
                                                                    </>
                                                                )}
                                                                {showPrivacy && (
                                                                    <>
                                                                        <div className="term-back" onClick={termClose}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                                                                <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <Privacy></Privacy>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}


                                                    </Step>
                                                ))}
                                            </Steps>
                                        </div>
                                    </AnimateHeight>

                                    {welcometoShow && (
                                        <Steps>
                                            {stepsMain.map(stepMain => (
                                                <Step key={stepMain.id} id={stepMain.id}>

                                                    <div className="preload">{stepMain.preload}</div>
                                                    {stepMain.component}
                                                </Step>
                                            ))}
                                        </Steps>
                                    )}


                                    {buttonShow && (
                                        <div className="block-button">
                                            <WithWizard
                                                render={({ next, previous, step, steps }) => (
                                                    <>
                                                        <MyButton
                                                            // ref={nextPageButtonRef}
                                                            buttonStatus={buttonStatus}
                                                            buttonTime={buttonTime}
                                                            buttonText={buttonText}
                                                            onClick={() => handleClick(next)}
                                                            className={(areAllCheckboxesUnchecked() || areAllRadioUnchecked()) ? (error ? 'error' : 'disable') : undefined}
                                                        >
                                                        </MyButton>
                                                        {handShow && (
                                                            <div className="hand" >
                                                                <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_11035_53290)">
                                                                        <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04Z" fill="white"></path>
                                                                        <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04ZM16.573 16.2051C17.1738 15.8582 17.9337 16.0618 18.2805 16.6626L25.1555 28.5705L27.3206 27.3205L24.8206 22.9903C24.4737 22.3895 24.6773 21.6297 25.2781 21.2828C25.8789 20.9359 26.6388 21.1395 26.9857 21.7403L29.4857 26.0705L31.6507 24.8205L29.1507 20.4903C28.8038 19.8895 29.0074 19.1297 29.6083 18.7828C30.2091 18.4359 30.9689 18.6395 31.3158 19.2403L33.8158 23.5705L36.0826 22.2617L34.8326 20.0967C34.4857 19.4958 34.6893 18.736 35.2901 18.3891C35.8899 18.0429 36.6508 18.2458 36.9977 18.8467L41.8808 27.3045C44.0458 31.0544 42.7999 35.704 39.049 37.8696L37.1892 38.9433C35.6033 39.859 33.9059 40.0841 32.2744 39.8857L18.5124 36.2842C17.7525 36.0806 17.4494 35.5556 17.653 34.7957C17.8577 34.0353 18.3817 33.7328 19.1415 33.9364L26.5061 35.9097L16.1155 17.9126C15.7686 17.3118 15.9722 16.552 16.573 16.2051Z" fill="black"></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_11035_53290">
                                                                            <rect width="40" height="40" fill="white" transform="translate(0 20) rotate(-30)"></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </div>
                                                        )}

                                                        {buttonSkip && (
                                                            <div className="skip" onClick={next}>
                                                                Skip
                                                            </div>
                                                        )}
                                                        {signupBottom == 'checkbox' && (
                                                            <Reveal cascade keyframes={customAnimation} >
                                                                <div className="googlesingup-bottom">
                                                                    {window.location.pathname === '/signup' ? (
                                                                        <>
                                                                            {loginChangeStatus && (
                                                                                <div className="googlesingup-bottom-login">
                                                                                    Already have an account? <Link onClick={loginChange} style={{ cursor: 'pointer', textDecoration: 'underline', textDecorationColor: '#000' }}>Log in</Link>
                                                                                </div>
                                                                            )}
                                                                            <div className="googlesingup-bottom-term">
                                                                                By continuing I agree to the<br /> <span onClick={termChange}>Terms & Conditions</span>, <span onClick={privacyChange}>Privacy Policy</span>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="googlesingup-bottom-login">
                                                                            <Link onClick={() => {

                                                                                history.push('/signup');

                                                                                mixpanel.track('web_signup_2_act', {
                                                                                    session_id: sessionId,
                                                                                    web_utm_campaign: localStorage.getItem("utmCampaign"),
                                                                                    web_utm_source: localStorage.getItem("utmSource"),
                                                                                    web_utm_medium: localStorage.getItem("utmMedium"),
                                                                                    web_utm_content: localStorage.getItem("utmContent"),
                                                                                    web_utm_term: localStorage.getItem("utmTerm"),
                                                                                    isDebug: false,
                                                                                    funnel: 'MAIN_v1',
                                                                                });
                                                                            }} style={{ cursor: 'pointer', textDecoration: 'none' }}>Sign up with a new email</Link>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                                {/* <div className="signup-bottom">
                                                                    <div className="signup-bottom-checkbox">
                                                                        <label>
                                                                            <CustomCheckbox
                                                                                style={{ marginRight: '10rem' }}
                                                                                checked={isChecked}
                                                                                onChange={handleChange}
                                                                            >

                                                                            </CustomCheckbox>
                                                                            <p>By clicking on sign-up, you agree to Zeely's <span onClick={termChange}>Terms and Conditions</span> and <span onClick={privacyChange}>Privacy Policy.</span></p>

                                                                        </label>
                                                                    </div>
                                                                    <div class="block-bottom">
                                                                        By continuing I agree to the<br />
                                                                        <span onClick={termChange}>Terms & Conditions</span>, <span onClick={privacyChange}>Privacy Policy.</span>

                                                                        {/* <div className="signup-bottom-text">
                                                                            Zeely, Inc. 2810 N Church St, Ste 27833,
                                                                            Wilmington, DE 19802-4447
                                                                        </div> */}
                                                                {/* </div> */}
                                                                {/* </div> */}
                                                            </Reveal>
                                                        )}
                                                        {signupBottom == 'forget' && (
                                                            <Reveal cascade keyframes={customAnimation} >
                                                                <div className="forget" onClick={handleForget}>
                                                                    Forgot password?
                                                                </div>
                                                            </Reveal>
                                                        )}
                                                        {signupBottom == 'loginChange' && (
                                                            <Reveal cascade keyframes={customAnimation} >
                                                                <div className="forget" onClick={handleForget}>
                                                                    Forgot password?
                                                                </div>
                                                            </Reveal>
                                                        )}
                                                        {signupBottom == 'EnterCode' && (
                                                            <Reveal cascade keyframes={customAnimation} >
                                                                {timeLeft > 0 ? (
                                                                    <div className="forget">
                                                                        Wait 00:{timeLeft} to resend
                                                                    </div>
                                                                ) : (
                                                                    <div className="forget" onClick={handleForget}>
                                                                        I didn't receive a code
                                                                    </div>
                                                                )}
                                                            </Reveal>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )}
                                    {buttonChoose && (
                                        <div className="block-button">
                                            <WithWizard
                                                render={({ next, previous, step, steps }) => (
                                                    <>
                                                        <div className='choose-action'>
                                                            <div className="choose-action-btn" onClick={(event) => handleClickChoose(next, event.currentTarget, creativeId, false)}>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.24485 4.49493C5.5238 4.21598 5.97606 4.21598 6.25501 4.49493L10.7499 8.98929L15.2449 4.49493C15.5023 4.23744 15.9075 4.21763 16.1877 4.43551L16.255 4.49493C16.534 4.77388 16.534 5.22614 16.255 5.50508L11.7606 10L16.255 14.4949C16.5125 14.7524 16.5323 15.1576 16.3144 15.4378L16.255 15.5051C15.9761 15.784 15.5238 15.784 15.2449 15.5051L10.7499 11.0107L6.25501 15.5051C5.99752 15.7626 5.59235 15.7824 5.31215 15.5645L5.24485 15.5051C4.96591 15.2261 4.96591 14.7739 5.24485 14.4949L9.73922 10L5.24485 5.50508C4.98737 5.24759 4.96756 4.84243 5.18543 4.56222L5.24485 4.49493Z" fill="#EF2C4F" stroke="#EF2C4F" stroke-width="0.4" />
                                                                </svg>
                                                                No
                                                            </div>
                                                            <div className="choose-action-btn" onClick={(event) => handleClickChoose(next, event.currentTarget, creativeId, true)}>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.60693 13.2756L4.8263 9.49492C4.54735 9.21598 4.09509 9.21598 3.81614 9.49492C3.5372 9.77387 3.5372 10.2261 3.81614 10.5051L8.10186 14.7908C8.3808 15.0697 8.83306 15.0697 9.11201 14.7908L17.6834 6.21936C17.9624 5.94042 17.9624 5.48816 17.6834 5.20921C17.4045 4.93026 16.9522 4.93026 16.6733 5.20921L8.60693 13.2756Z" fill="#5BF0A5" stroke="#5BF0A5" stroke-width="0.444444" />
                                                                </svg>
                                                                Yes
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>



                                {showBrandName && (
                                    <div className="brandname">
                                        <div className="brandname-wrapper">
                                            <BrandName BusinessAreaHave={BusinessAreaHave} setBusinessAreaHave={setBusinessAreaHave} WebsiteStatus={WebsiteStatus} />


                                        </div>
                                    </div>
                                )}


                                {showWebSite && (
                                    <div className="brandname">
                                        <div className="brandname-wrapper">
                                            <Website BusinessAreaHave={BusinessAreaHave} />


                                        </div>
                                    </div>
                                )}

                                {showTermBundle && (
                                    <div className="brandname">
                                        <div className="brandname-wrapper">
                                            <div className="term-back" onClick={termBundleClose}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                                    <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                                                </svg>
                                            </div>
                                            <Term />
                                        </div>
                                    </div>
                                )}


                            </div>


                        )}
                    />


                )}
            />
        </Router>

    );
}

export default App;
