import { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import './MyButton.scss';

const MyButton = ({ children, className, onClick, buttonText, buttonTime, buttonIcon, buttonStatus, ...props }) => {

    const myElementRef = useRef(null);

    const [icon, setIcon] = useState('');
    useEffect(() => {
        if (buttonIcon !== undefined) {
            setIcon(buttonIcon);
        } else {
            setIcon('');
        }
    }, [buttonIcon]);

    const [animate, setAnimate] = useState(false);

    const handleClick = () => {

        if (className === 'disable' || className === 'error') {
            if (onClick) {
                onClick();
            }
            return;
        }
        setAnimate(true);

        myElementRef.current.style.pointerEvents = 'none';
        const tl = gsap.timeline();
        tl.to(myElementRef.current, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            ease: "power4.out",
        });

        tl.to(myElementRef.current, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });


        // if ('vibrate' in navigator) {
        //     navigator.vibrate(100);
        // }
        setTimeout(() => {
            if (onClick) {
                myElementRef.current.style.pointerEvents = 'auto';
                onClick();
            }
        }, buttonTime);
        setTimeout(() => {
            setAnimate(false);
        }, 635);

    };



    const combinedClassName = `btn ${className || ''} ${animate ? 'animate' : ''} ${buttonStatus === "white" ? 'white' : ''} ${buttonStatus === "green" ? 'green' : ''}`;

    return (
        <button {...props} className={`${combinedClassName} ${icon === "PayPal" ? "paypal-button" : ""}  ${buttonStatus == 'load' ? "pointerNone" : ""}  `} onClick={handleClick} ref={myElementRef}>
            {buttonStatus == 'load' ? (
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            ) : (
                <>
                    {icon === "lock" && (


                        <svg style={{ width: "16rem", height: "18rem", marginLeft: '0', marginRight: '12rem' }} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99833 0.427734C10.3653 0.427734 12.284 2.34651 12.284 4.71345L12.2855 6.15826C12.8875 6.19557 13.2716 6.30091 13.6675 6.51265C14.1412 6.76598 14.5172 7.142 14.7705 7.61569C15.0457 8.13017 15.1412 8.62462 15.1412 9.60332V14.1093C15.1412 15.088 15.0457 15.5824 14.7705 16.0969C14.5172 16.5706 14.1412 16.9466 13.6675 17.2C13.153 17.4751 12.6586 17.5706 11.6799 17.5706H4.31677C3.33807 17.5706 2.84362 17.4751 2.32914 17.2C1.85545 16.9466 1.47943 16.5706 1.2261 16.0969C0.950951 15.5824 0.855469 15.088 0.855469 14.1093V9.60332C0.855469 8.62462 0.950951 8.13017 1.2261 7.61569C1.47943 7.142 1.85545 6.76598 2.32914 6.51265C2.72522 6.30082 3.10943 6.19548 3.71186 6.15822L3.71261 4.71345C3.71261 2.34651 5.63139 0.427734 7.99833 0.427734ZM11.6799 7.57059H4.31677C3.55373 7.57059 3.28208 7.62305 3.00285 7.77239C2.77812 7.89257 2.60602 8.06467 2.48583 8.2894C2.3365 8.56863 2.28404 8.84028 2.28404 9.60332V14.1093C2.28404 14.8723 2.3365 15.144 2.48583 15.4232C2.60602 15.6479 2.77812 15.82 3.00285 15.9402C3.28208 16.0896 3.55373 16.142 4.31677 16.142H11.6799C12.4429 16.142 12.7146 16.0896 12.9938 15.9402C13.2185 15.82 13.3906 15.6479 13.5108 15.4232C13.6602 15.144 13.7126 14.8723 13.7126 14.1093V9.60332C13.7126 8.84028 13.6602 8.56863 13.5108 8.2894C13.3906 8.06467 13.2185 7.89257 12.9938 7.77239C12.7146 7.62305 12.4429 7.57059 11.6799 7.57059ZM7.99833 9.71345C8.39282 9.71345 8.71261 10.0332 8.71261 10.4277V12.5706C8.71261 12.9651 8.39282 13.2849 7.99833 13.2849C7.60384 13.2849 7.28404 12.9651 7.28404 12.5706V10.4277C7.28404 10.0332 7.60384 9.71345 7.99833 9.71345ZM7.99833 1.85631C6.42037 1.85631 5.14118 3.13549 5.14118 4.71345V6.14202H10.8555V4.71345C10.8555 3.13549 9.57628 1.85631 7.99833 1.85631Z" fill="#5BF0A5" />
                        </svg>

                    )}
                    {buttonText}
                    {icon === "applePay" && (
                        <svg style={{ width: "47rem", height: "24rem", marginTop: '-2rem' }} width="47" height="24" viewBox="0 0 47 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_67341)">
                                <path d="M22.9894 4.62109C25.6363 4.62109 27.4605 6.56814 27.4605 9.35508C27.4605 12.1802 25.6005 14.1273 22.9178 14.1273H20.0205V19.0521H17.9102V4.62109H22.9894ZM20.0205 12.2566H22.4171C24.2413 12.2566 25.2786 11.1876 25.2786 9.39326C25.2786 7.56075 24.2413 6.52996 22.4171 6.52996H19.9848V12.2566H20.0205ZM27.9971 16.1125C27.9971 14.28 29.3205 13.1346 31.6813 12.9819L34.3998 12.8292V12.0275C34.3998 10.844 33.6486 10.1568 32.4325 10.1568C31.2521 10.1568 30.5367 10.7676 30.3578 11.6839H28.4263C28.5336 9.77504 30.0717 8.36247 32.504 8.36247C34.9005 8.36247 36.4386 9.69868 36.4386 11.8366V19.0903H34.5071V17.3723H34.4713C33.899 18.5558 32.6471 19.2812 31.3594 19.2812C29.3563 19.2812 27.9971 17.9832 27.9971 16.1125ZM34.3998 15.158V14.3181L31.9675 14.4708C30.7513 14.5472 30.0717 15.1199 30.0717 16.0361C30.0717 16.9524 30.7871 17.5632 31.8602 17.5632C33.2909 17.5632 34.3998 16.5324 34.3998 15.158ZM38.2271 22.9844V21.2282C38.3701 21.2664 38.7278 21.2664 38.8709 21.2664C39.8009 21.2664 40.3017 20.8465 40.6236 19.7775C40.6236 19.7393 40.8025 19.1285 40.8025 19.1285L37.2255 8.59154H39.4075L41.9113 17.1814H41.9471L44.4509 8.59154H46.5971L42.9128 19.7011C42.0544 22.259 41.0886 23.0607 39.0498 23.0607C38.9067 23.0226 38.4059 23.0226 38.2271 22.9844Z" fill="white" />
                                <path d="M8.97577 4.91965C9.561 4.18861 9.95594 3.17264 9.84854 2.16016C9.00283 2.19588 7.98037 2.7422 7.37822 3.47063C6.83274 4.11716 6.36084 5.15229 6.49108 6.14473C7.4315 6.21967 8.39054 5.65069 8.97577 4.91965ZM11.4055 11.3448C11.3869 9.15517 13.1392 8.10173 13.2204 8.04945C12.2317 6.5682 10.6985 6.36257 10.1521 6.33991C8.84553 6.2066 7.5998 7.13282 6.9393 7.13282C6.27627 7.13282 5.25634 6.36082 4.17045 6.38435C2.74628 6.40526 1.4329 7.23738 0.699668 8.55047C-0.776093 11.2019 0.32502 15.1177 1.76611 17.262C2.47143 18.3119 3.31037 19.4917 4.41571 19.449C5.47961 19.4063 5.87879 18.7398 7.16426 18.7398C8.4472 18.738 8.81001 19.4473 9.93057 19.4272C11.074 19.402 11.7996 18.3555 12.5007 17.3003C13.3066 16.0848 13.6407 14.9042 13.6576 14.8449C13.6348 14.8292 11.4309 13.9631 11.4055 11.3448Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_67341">
                                    <rect width="46.5" height="22.1429" fill="white" transform="translate(0 0.929688)" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                    {icon === "PayPal" && (
                        <svg style={{ width: "59rem", height: "22rem" }} width="59" height="22" viewBox="0 0 59 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_9469_25958)">
                                <path d="M2.68465 4.32227C2.51387 4.32227 2.36881 4.44625 2.3421 4.61508L0.365205 17.152C0.358022 17.1978 0.360844 17.2446 0.373475 17.2892C0.386107 17.3338 0.408252 17.3752 0.438382 17.4104C0.468511 17.4456 0.505902 17.4739 0.548005 17.4933C0.590108 17.5127 0.635919 17.5227 0.682275 17.5227H3.02672C3.19726 17.5227 3.34256 17.3985 3.36927 17.2299L3.94999 13.5484C3.97645 13.3798 4.12176 13.2555 4.29254 13.2555H6.42405C8.91453 13.2555 11.0287 11.4384 11.4148 8.97611C11.8042 6.49298 9.86427 4.32766 7.11479 4.32227H2.68465ZM4.96368 7.12099H6.66908C8.07309 7.12099 8.53033 7.94944 8.38576 8.8656C8.2412 9.78373 7.5311 10.46 6.1729 10.46H4.4371L4.96368 7.12099ZM16.1831 7.9732C15.5911 7.97541 14.9099 8.0967 14.1452 8.41524C12.391 9.14641 11.5486 10.6575 11.1909 11.7592C11.1909 11.7592 10.0522 15.1202 12.6248 16.9668C12.6248 16.9668 15.0106 18.7445 17.6964 16.8572L17.6501 17.152C17.6429 17.1978 17.6457 17.2445 17.6583 17.2891C17.6709 17.3337 17.693 17.375 17.7231 17.4102C17.7532 17.4454 17.7905 17.4737 17.8325 17.4931C17.8746 17.5126 17.9203 17.5227 17.9666 17.5227H20.1923C20.363 17.5227 20.5081 17.3985 20.5348 17.2299L21.8886 8.64459C21.8959 8.59879 21.8931 8.55197 21.8805 8.50735C21.868 8.46272 21.8459 8.42135 21.8158 8.38607C21.7857 8.35079 21.7483 8.32245 21.7063 8.30299C21.6642 8.28354 21.6184 8.27343 21.572 8.27337H19.3464C19.1756 8.27337 19.0303 8.3976 19.0039 8.56618L18.9311 9.0283C18.9311 9.0283 17.9593 7.96659 16.1831 7.9732ZM16.2559 10.6849C16.5114 10.6849 16.7452 10.7202 16.9549 10.7876C17.9152 11.0959 18.4597 12.0184 18.3021 13.0184C18.1078 14.2496 17.098 15.1562 15.803 15.1562C15.5477 15.1562 15.314 15.1212 15.104 15.0538C14.1439 14.7456 13.5961 13.823 13.7536 12.8231C13.9479 11.5918 14.9609 10.6849 16.2559 10.6849Z" fill="#003087" />
                                <path d="M34.7354 4.32227C34.5647 4.32227 34.4196 4.44625 34.3929 4.61508L32.416 17.152C32.4088 17.1978 32.4116 17.2446 32.4243 17.2892C32.4369 17.3338 32.459 17.3752 32.4892 17.4104C32.5193 17.4456 32.5567 17.4739 32.5988 17.4933C32.6409 17.5127 32.6867 17.5227 32.7331 17.5227H35.0775C35.248 17.5227 35.3933 17.3985 35.4201 17.2299L36.0008 13.5484C36.0272 13.3798 36.1725 13.2555 36.3433 13.2555H38.4748C40.9653 13.2555 43.0792 11.4384 43.4651 8.97611C43.8547 6.49298 41.915 4.32766 39.1656 4.32227H34.7354ZM37.0145 7.12099H38.7199C40.1239 7.12099 40.5811 7.94944 40.4365 8.8656C40.292 9.78373 39.5821 10.46 38.2237 10.46H36.4879L37.0145 7.12099ZM48.2336 7.9732C47.6416 7.97541 46.9604 8.0967 46.1957 8.41524C44.4415 9.14641 43.5991 10.6575 43.2414 11.7592C43.2414 11.7592 42.1032 15.1202 44.6758 16.9668C44.6758 16.9668 47.0612 18.7445 49.7472 16.8572L49.7009 17.152C49.6937 17.1978 49.6965 17.2446 49.7091 17.2892C49.7218 17.3338 49.7439 17.3752 49.774 17.4104C49.8042 17.4456 49.8416 17.4739 49.8837 17.4933C49.9258 17.5127 49.9716 17.5227 50.0179 17.5227H52.2433C52.4141 17.5227 52.5591 17.3985 52.5858 17.2299L53.9401 8.64459C53.9474 8.59875 53.9446 8.55189 53.932 8.50723C53.9194 8.46257 53.8973 8.42117 53.8672 8.38588C53.837 8.3506 53.7996 8.32227 53.7575 8.30284C53.7153 8.28342 53.6694 8.27336 53.623 8.27337H51.3974C51.2267 8.27337 51.0814 8.3976 51.0549 8.56618L50.9821 9.0283C50.9821 9.0283 50.0098 7.96659 48.2336 7.9732ZM48.3064 10.6849C48.562 10.6849 48.7957 10.7202 49.0055 10.7876C49.9657 11.0959 50.5102 12.0184 50.3526 13.0184C50.1583 14.2496 49.1486 15.1562 47.8536 15.1562C47.5983 15.1562 47.3645 15.1212 47.1545 15.0538C46.1945 14.7456 45.6466 13.823 45.8042 12.8231C45.9985 11.5918 47.0114 10.6849 48.3064 10.6849Z" fill="#0070E0" />
                                <path d="M22.9442 8.27368C22.7678 8.27368 22.6423 8.44594 22.6962 8.61378L25.1362 16.1854L22.93 19.7536C22.8229 19.9268 22.9474 20.1505 23.151 20.1505H25.7586C25.8332 20.1504 25.9065 20.1311 25.9714 20.0945C26.0364 20.0578 26.0907 20.0051 26.1293 19.9412L32.9438 8.66818C33.0485 8.49494 32.9235 8.27344 32.7211 8.27344H30.1138C30.0385 8.2735 29.9645 8.29317 29.8991 8.3305C29.8337 8.36784 29.7791 8.42156 29.7408 8.48637L27.0585 13.015L25.6969 8.51994C25.6525 8.37365 25.5175 8.27344 25.3648 8.27344L22.9442 8.27368Z" fill="#003087" />
                                <path d="M56.4665 4.32227C56.2959 4.32227 56.1506 4.4465 56.1239 4.61508L54.1465 17.1515C54.1393 17.1974 54.142 17.2442 54.1546 17.2889C54.1672 17.3335 54.1894 17.3749 54.2195 17.4102C54.2496 17.4455 54.2871 17.4738 54.3292 17.4933C54.3713 17.5127 54.4172 17.5227 54.4636 17.5227H56.808C56.9786 17.5227 57.1239 17.3985 57.1506 17.2299L59.1275 4.693C59.1347 4.64724 59.1319 4.60047 59.1193 4.55591C59.1067 4.51134 59.0846 4.47003 59.0545 4.43481C59.0244 4.39959 58.9871 4.37129 58.945 4.35187C58.903 4.33244 58.8572 4.32234 58.8109 4.32227H56.4665Z" fill="#0070E0" />
                            </g>
                            <defs>
                                <clipPath id="clip0_9469_25958">
                                    <rect width="58.3" height="22" fill="white" transform="translate(0.349609)" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}

                    {icon === "" && (
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    )}
                </>
            )}



        </button>
    );
};

export default MyButton;