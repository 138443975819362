import React, { useState } from 'react'
import './priceInfo.scss'
import mixpanel from 'mixpanel-browser';

const PriceInfo = ({text, setShowTermBundle, sessionId, eventName}) => {
    const [showInfo, setShowInfo] = useState(false);

    const onShowInfo = () => {
        setShowInfo(true);
        mixpanel.track(eventName, {
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            session_id: sessionId,
            user_id: localStorage.getItem('userId'),
            web_plan: localStorage.getItem('plan'),
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
    }

    const closeShowInfo = () => {
        setShowInfo(false);
    }
    const termChange = () => {
        setShowTermBundle(true)
    };

    return (
        <>
            <div className="priceInfo">
                {text}
                <span style={{ width: '15rem', height: '15rem', display: 'flex' }} onClick={onShowInfo}>
                    <svg style={{ width: '15rem', height: '15rem' }} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="1" width="13" height="13" rx="6.5" stroke="#8F969C" />
                        <path d="M7.03022 3.59961C7.78922 3.59961 8.42722 3.81961 8.92222 4.25961C9.41722 4.71061 9.67022 5.27161 9.67022 5.94261C9.67022 6.54761 9.47222 7.05361 9.07622 7.47161C8.66922 7.88961 8.15222 8.13161 7.51422 8.20861L7.49222 9.27561H6.46922L6.41422 7.47161H6.61222C7.80022 7.47161 8.51522 6.85561 8.51522 5.98661C8.51522 5.16161 7.88822 4.54561 6.99722 4.54561C6.11722 4.54561 5.47922 5.15061 5.44622 5.97561H4.32422C4.32422 5.28261 4.57722 4.72161 5.09422 4.27061C5.60022 3.81961 6.24922 3.59961 7.03022 3.59961ZM6.43622 10.0786C6.73322 9.80361 7.28322 9.80361 7.59122 10.0786C7.88822 10.3536 7.88822 10.9036 7.59122 11.1786C7.28322 11.4536 6.73322 11.4536 6.43622 11.1786C6.12822 10.9036 6.12822 10.3536 6.43622 10.0786Z" fill="#8F969C" />
                    </svg>
                    {showInfo && (
                        <b></b>
                    )}
                </span>
                {showInfo && (
                    <div className="priceInfo-wrapper">
                        <div className="priceInfo__title">Pricing</div>
                        <div className="priceInfo__close" onClick={closeShowInfo}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.5L13 13.5" stroke="white" stroke-linecap="round" />
                                <path d="M13 3.5L3 13.5" stroke="white" stroke-linecap="round" />
                            </svg>
                        </div>
                        <p>We've automatically applied a discount to your first subscription. Please be aware that your subscription will renew automatically at the full price of $79.95 per month once your initial term ends. You can manage your subscription either through the app or by contacting support@zeely.app. Learn more in our <i onClick={termChange}>Subscription Policy.</i></p>
                    </div>
                )}

            </div>
        </>
    )
}

export default PriceInfo