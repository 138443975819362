import React from 'react';
import { Helmet } from "react-helmet";
const CustomTitle = ({title, style}) => {
    const sanitizedTitle = title.replace(/<br\s*\/?>/g, '');
    return (
        <>
            <Helmet>
                <title>{sanitizedTitle}</title>
            </Helmet>
            <h1 style={style} dangerouslySetInnerHTML={{ __html: title }}></h1>
        </>
    );
}

export default CustomTitle;
