import React, { useEffect, useState } from 'react';
import { WithWizard } from 'react-albus-react18';
import MyButton from '../components/UI/button/MyButton';
import CustomTitle from '../components/UI/title/CustomTitle';
import mixpanel from 'mixpanel-browser';
const Predict = ({ setButtonShow,setButtonStatus, sessionId, setSignupBottom }) => {


    const [predictStart, setPredictStart] = useState('Dec 23')
    const [predictEnd, setPredictEnd] = useState('Apr 24')
    const [name, setName] = useState("");
    const [orders, setOrders] = useState('');
    const [date, setDate] = useState();
    useEffect(() => {
        setButtonStatus('')
        setSignupBottom('')
        const predictValue = localStorage.getItem('predict');
        const today = new Date();

        let daysToAdd;
        switch (predictValue) {
            case 'option1':
                daysToAdd = 75;
                break;
            case 'option2':
                daysToAdd = 120;
                break;
            case 'option3':
                daysToAdd = 210;
                break;
            case 'option4':
                daysToAdd = 300;
                break;
            default:
                daysToAdd = 0;
        }
        const endDate = new Date();
        endDate.setDate(today.getDate() + daysToAdd);
        setPredictStart(`${today.toLocaleString('en-US', { month: 'short' })} ${today.getDate()}`);
        setPredictEnd(`${endDate.toLocaleString('en-US', { month: 'short' })} ${endDate.getDate()}`);
        // setDate(`${endDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}`);
        setDate(`${endDate.toLocaleString('en-US', { month: 'long' })} ${endDate.getDate()}`);
        setButtonShow(false);
        if (localStorage.getItem('name')) {
            setName(localStorage.getItem('name'))
        }
        if (localStorage.getItem('order2')) {
            const order2 = localStorage.getItem('order2');
            const [minValue, maxValue] = order2.replace('+', '').split(' - ').map(Number);
            const result = (maxValue - minValue) / 2 + minValue;
            if (result >= 1000) {
                setOrders((result / 1000).toFixed(1).replace('.0', '') + 'k')
            } else {
                setOrders(result)
            }
        }

    }, []);

    const handleClick = (next) => {
        const webEntrance = localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup';
        mixpanel.track('web_prediction', {
          session_id: sessionId,
          web_entrance: webEntrance,
          user_id: localStorage.getItem('userId'),
          web_utm_campaign: localStorage.getItem("utmCampaign"),
          web_utm_source: localStorage.getItem("utmSource"),
          web_utm_medium: localStorage.getItem("utmMedium"),
          web_utm_content: localStorage.getItem("utmContent"),
          web_utm_term: localStorage.getItem("utmTerm"),
          isDebug: false,
funnel: 'MAIN_v1',
        });
        next();
      };

    return (
        <div >
            <CustomTitle style={{ marginBottom: "24rem" }} title={`
            ${name}, with your new plan you'll get ${orders} orders by ${date}`} />
            <div style={{ aspectRatio: "258/203", margin: '0 auto', width: '275rem', position: 'relative' }} >
                <span style={{
                    position: "absolute",
                    width: '76rem',
                    height: '42rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12rem',
                    textAlign: 'center',
                    fontWeight: '500',
                    lineHeight: '1.2',
                    top: 0,
                    right: 0,
                }}>Goal:<br /> {orders} orders</span>
                <img src={process.env.PUBLIC_URL + '/img/Predict.webp'} alt="" />
            </div>
            <div className="predict-wrapper" style={{ margin: '12rem auto 0', width: '275rem' }}>
                <div className="predict-item">
                    {predictStart}
                </div>
                <div className="predict-item">
                    {predictEnd}
                </div>
            </div>
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton style={{ marginTop: "32rem" }} 
                    onClick={() => handleClick(next)}
                   
 buttonText={'Continue'}>

                    </MyButton >
                )}
            />
        </div>
    );
}

export default Predict;
