import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './price.scss'
import { Helmet } from 'react-helmet';
import PriceInfo from '../../components/PriceInfo/PriceInfo';
const Price = ({  setShowTermBundle, setButtonText, setButtonShow, setPriceBottomShow, setWhiteWrapper, setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonText("Get my plan");
        setButtonShow(true);
        if (setWhiteWrapper) {
            setWhiteWrapper(false)
        }
        setEventName('web_price')
        setEventProperties({
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            session_id: sessionId,
            user_id: localStorage.getItem('userId')
        })
        setPriceBottomShow(true);
        localStorage.setItem("price", 'plus')
        localStorage.setItem("plan", '4-Week Plan')
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const [activePlan, setActivePlan] = useState('plus');

    const plans = [
        {
            id: 'growth',
            name: '2-Week Trial',
            price: '$19.95',
            oldPrice: '$42.00',
            priceDay: '$1.42',
            oldPriceDay: '$2.97',
            // features: [
            //     {
            //         text: 'High-performing Facebook & Insta ads',
            //         status: true
            //     },
            //     {
            //         text: 'Sales-oriented AI ad creatives',
            //         status: true
            //     },
            //     {
            //         text: 'Sales page for products & services',
            //         status: true
            //     },
            //     {
            //         text: 'Stripe & PayPal payment processing',
            //         status: true
            //     },
            // ]
        },
        {
            id: 'plus',
            name: '4-Week Plan',
            price: '$29.95',
            oldPrice: '$83.00',
            activeTrial: 'Most Popular',
            priceDay: '$1.06',
            oldPriceDay: '$2.35',

        },
        {
            id: 'starter',
            name: '8-Week Plan',
            price: '$49.95',
            oldPrice: '$167.00',
            priceDay: '$0.89',
            oldPriceDay: '$1.77',
        },
    ];

    const handlePlanClick = (planId, planName) => {
        setActivePlan(planId);

        localStorage.setItem("price", planId)
        localStorage.setItem("plan", planName)

    };

    return (
        <div className="price-container">
            <Helmet>
                <meta name="theme-color" content="#000"></meta>
            </Helmet>
            <CustomTitle style={{ marginBottom: "24rem" }} title="Choose your plan with<br>up to 70% discount" />

            <div className="price">
                {plans.map((plan) => (
                    <div
                        key={plan.id}
                        className={`price-plans__plan ${activePlan === plan.id ? 'price-plans__plan--active' : ''}`}
                        onClick={() => handlePlanClick(plan.id, plan.name)}
                    >
                        {plan.activeTrial &&
                            <div className="price-plans__plan-active-trial">{plan.activeTrial}</div>}
                        <div className="price-plans__top">
                            <div className="price-plans__top-icon">
                                <span></span>
                            </div>
                            <div className="price-plans__top-info">
                                <h2 className="price-plans__plan-title">{plan.name}</h2>
                                <p className="price-plans__plan-price">
                                    <span>{plan.oldPrice}</span>
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.23407 0.129553C5.07184 0.289498 5.05662 0.542236 5.18961 0.719567L5.22955 0.765933L7.975 3.55L0.95 3.55C0.701472 3.55 0.5 3.75147 0.5 4C0.5 4.22941 0.671668 4.41873 0.893553 4.44649L0.95 4.45H7.975L5.22955 7.23407C5.06961 7.3963 5.05798 7.64923 5.19347 7.82465L5.23407 7.87045C5.3963 8.03039 5.64922 8.04202 5.82465 7.90653L5.87045 7.86593L9.37045 4.31593C9.52879 4.15533 9.54198 3.90544 9.41003 3.72996L9.37045 3.68407L5.87045 0.134067C5.69596 -0.0429112 5.41104 -0.0449318 5.23407 0.129553Z" fill="#8F969C" />
                                    </svg>

                                    {plan.price}</p>
                            </div>
                            <div className="price-plans__top-trial">

                                <div className="price-plans__top-trial-last">
                                    <i>{plan.oldPriceDay}</i>
                                    <small>{plan.priceDay}</small>
                                    <span>per day</span>
                                </div>

                            </div>
                        </div>



                        {/* {activePlan === plan.id && (
                            <ul className="price-plans__features">
                                {plan.features.map((feature, index) => (
                                    <li key={index} className={!feature.status ? 'deactive' : ''}>
                                        {feature.status ? (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.30859 6.00691L4.8727 8.3146L10.0009 3.69922" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                                            </svg>
                                        ) : (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.17188 3.17188L8.82873 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M8.82812 3.17188L3.17127 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>

                                        )}
                                        {feature.text}
                                    </li>
                                ))}
                            </ul>
                        )} */}

                    </div>
                ))}
                <div style={{position: 'relative'}}>
                    <PriceInfo sessionId={sessionId} eventName={'web_click_pricepricing'} setShowTermBundle={setShowTermBundle} text="First subscription discounted automatically"/>
                </div>
            </div>
            <div className="plan-bottom">

            </div>

        </div>
    );
}

export default Price;
