import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './customRadio.scss';

const CustomRadio = ({ children,style,className, checked,  ...props }) => {
    const combinedClassName = `radioIcon ${className || ''}`;
    const checkboxRef = useRef(null);
    const checkboxIconRef = useRef(null);
    const checkboxTextRef = useRef(null);
    useEffect(() => {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline();
        const tl3 = gsap.timeline();
        if (checked == true) {
            tl2.to(checkboxIconRef.current, {
                delay: 0.15,
                opacity: 1,
                duration: 0.15,
                scale: 1,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 0.77,
                opacity: 0.32,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 1,
                stroke: "#000",
                opacity: 1,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 0.32,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 1,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
        } else {
            tl2.to(checkboxIconRef.current, {
                opacity: 0,
                duration: 0.15,
                scale: 0.77,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 0.77,
                duration: 0.15,
                opacity: 0.32,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 1,
                duration: 0.15,
                opacity: 1,
                ease: "back.inOut(1.4)",
                stroke: "#CAD4DD",
            });
        }
        return () => {
            tl.kill();
        };
        
    }, [checked]);

    const handleCheckboxChange = (event) => {
        // setIsChecked(event.target.checked);
      };
    return (
        <>
            <label className="radio" style={style}>
                <input type="radio" checked={checked} 
                onChange={handleCheckboxChange}
                {...props} />
                <span  ref={checkboxTextRef} >{children}</span>

                <div className={combinedClassName} >

                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect ref={checkboxRef} x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="black"/>
                    <circle ref={checkboxIconRef} cx="9.00156" cy="8.99961" r="5.4" fill="black"/>
                </svg>
{/* 
                    <svg  xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <circle cx="5.99961" cy="6.00001" r="5.4" fill="black" />
                    </svg> */}
                </div>

            </label>
        </>
    );
}

export default CustomRadio;
