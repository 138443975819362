import { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import './MyButton.scss';

const MyButtonReverse = ({ children, className, onClick, buttonText, buttonTime, buttonStatus, ...props }) => {
    const myElementRef = useRef(null);

    const [animate, setAnimate] = useState(false);

    const handleClick = () => {

        if (className === 'disable' || className === 'error') {
            if (onClick) {
                onClick();
            }
            return;
        }
        setAnimate(true);

        const tl = gsap.timeline();
        tl.to(myElementRef.current, {
            duration: 0.085,
            scale: 0.95,
            opacity: 0.85,
            ease: "power4.out",
        });

        tl.to(myElementRef.current, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });

       
        if ('vibrate' in navigator) {
            navigator.vibrate(100);
         }
        setTimeout(() => {
            if (onClick) {
                onClick();
            }
        }, buttonTime);
        setTimeout(() => {
            setAnimate(false);
        }, 635);

    };



    const combinedClassName = `btn ${className || ''} ${animate ? 'animate' : ''}`;

    return (
        <button {...props} className={combinedClassName} onClick={handleClick} ref={myElementRef}>
            {buttonStatus == 'load' ? (
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            ) : (
                <>{children}
                </>
                
            )}
            

        </button>
    );
};

export default MyButtonReverse;