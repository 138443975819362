import React from 'react';

const LogoDark = ({style}) => {
    return (
        <div style={style} className="logo"><svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.8492 11.0493C45.8245 9.19955 45.2074 7.6704 43.973 6.46188C42.7387 5.27803 41.1835 4.6861 39.2826 4.6861C37.3818 4.6861 35.8019 5.27803 34.5676 6.46188C33.3086 7.6704 32.6914 9.17489 32.6914 11.0247C32.6914 12.8744 33.3086 14.3789 34.5676 15.5628C35.8019 16.7466 37.3818 17.3386 39.2826 17.3386C40.7885 17.3386 42.1463 16.9193 43.3312 16.0561C44.5161 15.1928 45.2814 14.1076 45.627 12.7758H42.3931C41.9241 13.8856 40.7145 14.7242 39.3073 14.7242C37.6287 14.7242 36.2956 13.6144 35.9006 12.0112H45.7505C45.8245 11.7399 45.8492 11.4193 45.8492 11.0493ZM39.3073 7.30045C40.8626 7.30045 42.2203 8.21301 42.714 9.56951H36.0241C36.5425 8.18834 37.8015 7.30045 39.3073 7.30045Z" fill="black" />
            <path d="M59.8384 11.0493C59.8137 9.19955 59.1966 7.6704 57.9623 6.46188C56.728 5.27803 55.1727 4.6861 53.2719 4.6861C51.371 4.6861 49.7911 5.27803 48.5568 6.46188C47.2978 7.6704 46.6806 9.17489 46.6806 11.0247C46.6806 12.8744 47.2978 14.3789 48.5568 15.5628C49.7911 16.7466 51.371 17.3386 53.2719 17.3386C54.7777 17.3386 56.1355 16.9193 57.3204 16.0561C58.5054 15.1928 59.2706 14.1076 59.6163 12.7758H56.3823C55.9133 13.8856 54.7037 14.7242 53.2966 14.7242C51.6179 14.7242 50.2848 13.6144 49.8898 12.0112H59.7397C59.8137 11.7399 59.8384 11.4193 59.8384 11.0493ZM53.2966 7.30045C54.8518 7.30045 56.2095 8.21301 56.7033 9.56951H50.0133C50.5317 8.18834 51.7907 7.30045 53.2966 7.30045Z" fill="black" />
            <path d="M61.3364 17.1166H64.5456V0H61.3364V17.1166Z" fill="black" />
            <path d="M72.2983 12.6031L68.8915 4.93274H65.4602L70.7924 15.8094L67.6572 22H71.0146L78.9882 4.93274H75.6309L72.2983 12.6031Z" fill="black" />
            <path d="M28.8741 4.92969H32.542L24.1367 17.1139H20.4688L28.8741 4.92969Z" fill="black" />
            <path d="M20.8008 4.92969H29.8035V7.67711H20.8008V4.92969Z" fill="black" />
            <path d="M23.2793 14.3672H31.9358V17.1146H23.2793V14.3672Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.611262 14.0801L0.610657 14.0786H15.4581L15.4575 14.0801H0.611262ZM15.8486 12.8784C15.8 13.0816 15.7435 13.2817 15.6797 13.4785H0.389061C0.325249 13.2817 0.268841 13.0816 0.220165 12.8784H15.8486ZM3.80796 17.8306C3.80874 17.8311 3.80953 17.8315 3.81031 17.832H12.2585C12.2593 17.8315 12.2601 17.8311 12.2608 17.8306H3.80796ZM2.96491 17.2305C2.73407 17.0427 2.51386 16.8424 2.30534 16.6306H13.7635C13.5549 16.8424 13.3347 17.0427 13.1039 17.2305H2.96491ZM0.890553 14.6802C1.02445 14.9393 1.17205 15.1901 1.3324 15.4318H14.7364C14.8967 15.1901 15.0443 14.9393 15.1782 14.6802H0.890553ZM8.0344 19.0295C6.95586 19.0295 5.92696 18.8172 4.98728 18.4321H11.0815C10.1418 18.8172 9.11293 19.0295 8.0344 19.0295ZM0.0282105 11.6846C0.0113771 11.4846 0.00188359 11.2825 0 11.0786H16.0688C16.0669 11.2825 16.0574 11.4846 16.0406 11.6846H0.0282105ZM0.0776878 9.87988C0.11337 9.62531 0.161005 9.37457 0.22001 9.12823H15.8488C15.9078 9.37457 15.9554 9.62531 15.9911 9.87988H0.0776878ZM0.38933 8.52667L0.388858 8.52813H15.6799L15.6795 8.52667H0.38933ZM0.611001 7.92656C0.695967 7.7222 0.789143 7.5221 0.890118 7.32667H15.1787C15.2797 7.5221 15.3728 7.7222 15.4578 7.92656H0.611001ZM1.65096 6.12793C1.81143 5.91856 1.982 5.71731 2.16192 5.52491H13.9069C14.0868 5.71731 14.2574 5.91856 14.4178 6.12793H1.65096ZM8.0344 2.97656C9.68483 2.97656 11.219 3.47367 12.4952 4.32617H3.57364C4.84977 3.47367 6.38397 2.97656 8.0344 2.97656Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90363 6.125C7.23911 5.6877 7.61863 5.28587 8.03556 4.92615H2.78549C2.36856 5.28587 1.98905 5.6877 1.65356 6.125H6.90363ZM6.48513 6.72511H1.23507C0.994319 7.10618 0.784785 7.5089 0.610052 7.92969H5.86012C6.03485 7.5089 6.24439 7.10618 6.48513 6.72511ZM0.078311 9.87793C0.143356 9.41469 0.247983 8.96414 0.388666 8.5298H5.63873C5.49805 8.96414 5.39342 9.41469 5.32838 9.87793H0.078311ZM0.0169109 10.478C0.00569487 10.6516 0 10.8266 0 11.003C0 11.2309 0.00950828 11.4566 0.0281502 11.6797H5.27822C5.25957 11.4566 5.25007 11.2309 5.25007 11.003C5.25007 10.8266 5.25576 10.6516 5.26698 10.478H0.0169109ZM0.389367 13.4784C0.263748 13.0909 0.166824 12.6906 0.101097 12.2798H5.35116C5.41689 12.6906 5.51381 13.0909 5.63943 13.4784H0.389367ZM0.610952 14.0785C0.80828 14.5533 1.04992 15.0051 1.33072 15.4287H6.58079C6.29999 15.0051 6.05835 14.5533 5.86102 14.0785H0.610952ZM2.96286 17.2285C2.52433 16.8716 2.12423 16.4693 1.76971 16.0288H7.01978C7.37429 16.4693 7.77439 16.8716 8.21293 17.2285H2.96286ZM3.80515 17.8286C5.03362 18.5899 6.4828 19.0295 8.03475 19.0295C8.95398 19.0295 9.83716 18.8752 10.6598 18.5913C10.0936 18.3959 9.55606 18.139 9.05522 17.8286H3.80515ZM10.6598 3.4147C10.0055 3.64053 9.38956 3.94841 8.82425 4.32604H3.57418C4.85027 3.47362 6.3844 2.97656 8.03475 2.97656C8.95398 2.97656 9.83716 3.13077 10.6598 3.4147Z" fill="black" />
        </svg>

        </div>
    );
}

export default LogoDark;
